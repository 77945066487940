

export const getAllAssistantsAction = data => ({
    type: "GET_ALL_ASSISTANTS",
    params: data,
});

export const setAllAssistantsAction = data => ({
    type: "SET_ALL_ASSISTANTS",
    params: data,
});
export const setAllAssistantsLoadingAction = (data) => ({
    type: "SET_ALL_ASSISTANTS_LOADING",
    params: data,
});

export const setSelectedAssistantAction = (data) => ({
    type: "SET_SELECTED_ASSISTANT",
    params: data,
});

export const getHistorySessionsAction = (data) => ({
    type: "GET_HISTORY_SESSIONS",
    params: data,
}); 

export const getSessionHistoryChatAction = (data) => ({
    type: "GET_SESSION_HISTORY_CHAT",
    params: data,
}); 

export const sendNewPromptAction = (data) => ({
    type: "SEND_NEW_PROMPT",
    params: data,
}); 

export const generateTicketDetailsAction = (data) => ({
    type: "GENERATE_TICKET_DETAILS",
    params: data,
}); 

export const regeneratePromptAction = (data) => ({
    type: "REGENERATE_PROMPT",
    params: data,
}); 

export const clearHistoryAction = (data) => ({
    type: "CLEAR_HISTORY",
    params: data,
}); 

export const clearAllHistoryAction = (data) => ({
    type: "CLEAR_ALL_HISTORY",
    params: data,
}); 

export const validateAssistantHandleAction = (params) => ({
    type: "VALIDATE_ASSISTANT_HANDLE",
    params : params,
  });

export const improveWritingAction = (params) => ({
    type: "IMPROVE_WRITING",
    params : params,
  });

export const createAssistantAction = (params) => ({
    type: "CREATE_ASSISTANT",
    params : params,
  });

export const deleteAssistantAction = (params) => ({
    type: "DELETE_ASSISTANT",
    params : params,
  });

export const updateAssistantAction = (params) => ({
    type: "UPDATE_ASSISTANT",
    params : params,
  });

export const updateAssistantTaskAction = (params) => ({
    type: "UPDATE_ASSISTANT_TASK",
    params : params,
  });

export const deleteAssistantTaskAction = (params) => ({
    type: "DELETE_ASSISTANT_TASK",
    params : params,
  });

export const getAllAssistantTasksAction = (params) => ({
    type: "GET_ALL_ASSISTANT_TASKS",
    params : params,
  });

export const createAssistantTaskAction = (params) => ({
    type: "CREATE_ASSISTANT_TASK",
    params : params,
  });

export const uploadAssistantAudioAction = (params) => ({
    type: "UPLOAD_ASSISTANT_AUDIO",
    params : params,
  });

export const setAssistantMessagesAction = (params) => ({
    type: "SET_ASSISTANT_MESSAGES",
      params : params,
  });

export const setAssistantChatSessionIdAction = (params) => ({
    type: "SET_ASSISTANT_CHAT_SESSION_ID",
    params : params,
  });