import { put } from "redux-saga/effects";
import axios from "axios";
import {
  SET_TASKS,
  CREATE_NEW_TASK_SUCCESS,
  CREATE_NEW_TASK_FAILURE,
  HIDE_LOADER,
  SHOW_LOADER
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* createNewTaskSaga(params) {
  try {
    yield put({ type: SHOW_LOADER });
    let requestBody = { ...params.params };
    if (requestBody.hasOwnProperty('onSuccess')) {
      delete requestBody.onSuccess;
    }
    let createNewTaskResponse = yield axios.post(
      `${env.newBaseURLDev}/workspaces/action/`,
      requestBody,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (createNewTaskResponse.status === 200 || createNewTaskResponse.status === 201) {
      yield put({ type: CREATE_NEW_TASK_SUCCESS });
      window.showNotification({
        id: Date.now(),
        message: "Task created.",
        variant: "success",
        autoHide: true,
      });
      yield put({ type: HIDE_LOADER });
      if(params.params.onSuccess){
        params.params.onSuccess(createNewTaskResponse.data);
      }
    }
  } catch {
    yield put({ type: HIDE_LOADER });
    yield put({ type: CREATE_NEW_TASK_FAILURE });
  }
}

export function* getTasksSaga(params) {
  // const board = params?.params?.board;
  try {
    let getTasksResponse = yield axios.get(
      `${env.newBaseURLDev}/workspaces/boards/get_board_display/?board_id=${params?.params?.board_id}`,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (getTasksResponse.status === 200) {
      yield put({
        type: SET_TASKS,
        data: getTasksResponse.data,
      });
    }
  } catch {
    window.showNotification({
      id: Date.now(),
      message: "Something went wrong, please try again later.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* getActionActivitiesSaga(params) {
  try {
    if(params.params?.page === 1){
    yield put({
      type: "ACTION_ACTIVITIES_LOADING",
        data: true,
      });
    }
    
    let getAllActionActivitiesResponse = yield axios.get(
      `${env.newBaseURLDev}/notifications/notification?page=${params.params.page}&tab_type=${params.params.tab_type || "all"}&page_size=20&entity_id=${params.params.entity_id || ""}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );
    if (getAllActionActivitiesResponse.status === 200) {
      yield put({
        type: "SET_ALL_ACTION_ACTIVITIES",
        data: getAllActionActivitiesResponse.data,
      });
      yield put({
        type: "SET_ALL_ACTION_ACTIVITIES_UNREAD_COUNT",
        data: getAllActionActivitiesResponse.data.unread_count,
      });
      yield put({
        type: "ACTION_ACTIVITIES_LOADING",
        data: false,
      });
      if(params.params?.onSuccess){
        params.params.onSuccess(getAllActionActivitiesResponse.data);
      }
    }
  } catch {
    if(params.params?.onError){
      params.params.onError();
    }
    yield put({
      type: "SET_ALL_ACTION_ACTIVITIES",
      data: [],
    });
    yield put({
      type: "SET_ALL_ACTION_ACTIVITIES_UNREAD_COUNT",
      data: 0,
    });
    yield put({
      type: "ACTION_ACTIVITIES_LOADING",
      data: false,
    });
    window.showNotification({
      id: Date.now(),
      message: "Something went wrong, please try again later.",
      variant: "error",
      autoHide: true,
    });
  }
}