import { call, put } from 'redux-saga/effects';
import axios from 'axios';
const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* getAllWorkspacesSaga(params) {
  try {

    const getAllWorkspacesResponse = yield call(axios.get,
      `${env.newBaseURLDev}/workspaces/workspace/get_workspace_by_uid`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );

    if (getAllWorkspacesResponse.status === 200) {
      yield put({ type: "SET_ALL_WORKSPACES", data: getAllWorkspacesResponse.data });
      params.params.onSuccess(getAllWorkspacesResponse.data);
    }
  } catch (e) {

  }
}

export function* getPublicWorkspaceDetailsSaga(params) {
  try {
    const getPublicWorkspaceDetailsResponse = yield call(axios.get,
      `${env.newBaseURLDev}/workspaces/workspace/get_workspace_by_id?workspace_id=${params.params.workspaceId}`
    );

    if (getPublicWorkspaceDetailsResponse.status === 200) {
      if(params.params.onSuccess){
        params.params.onSuccess(getPublicWorkspaceDetailsResponse.data);
      }
    }
  } catch (e) {
    window.showNotification({
      id: Date.now(),
      message: "Failed to get workspace details.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* handleWorkspaceDetailsUpdateSaga(action) {
  try {
    const { workspaceName, actionKey, workspaceID, workspaceAvatar,visibility } = action.details;
    console.log(workspaceName, actionKey, workspaceID, workspaceAvatar)
    const postRequestForWorkspaceDetailsUpdate = yield call(fetch, `${env.newBaseURLDev}/workspaces/workspace/${workspaceID}/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        workspace_name: workspaceName,
        work_space_slug: actionKey,
        workspace_avatar: workspaceAvatar,
        ...(visibility && {visibility: visibility})
      }),
    });

    if (!postRequestForWorkspaceDetailsUpdate.ok) {
      throw new Error("Failed to update workspace details.");
    }else{
      yield put({ type: "GET_ALL_WORKSPACES", params: {} });
      window.showNotification({
        id: Date.now(),
        message: "Workspace has been updated successfully.",
        variant: "success",
        autoHide: true,
      });
    }

    // Update local storage
    const existingWorkspace = JSON.parse(localStorage.getItem("new_workspace"));
    if(existingWorkspace && existingWorkspace?.id !== workspaceID){ 
    
    }else{
      const updatedWorkspace = {
        ...existingWorkspace,
        workspace_name: workspaceName,
        work_space_slug: actionKey,
        ...(workspaceAvatar && { workspace_avatar: workspaceAvatar }),
        ...(visibility && {visibility: visibility})
      };
      localStorage.setItem("new_workspace", JSON.stringify(updatedWorkspace));
    }

  } catch (error) {
    console.error("Error updating workspace details:", error);
  }
}

export function* createNewWorkspaceSaga(params) {
  console.log({
    organization_id: params.params.organizationId,
    workspace_name: params.params.workspaceName,
    description: params.params.workspaceName,
    work_space_slug: params.params.actionKey,
    workspace_avatar: params.params.workspaceAvatar,
    visibility: params.params.visibility
  });
  try {

    const createNewWorkspaceResponse = yield axios.post(
      `${env.newBaseURLDev}/workspaces/workspace/`,
      {
        organization_id: params.params.organizationId,
        workspace_name: params.params.workspaceName,
        description: params.params.workspaceName,
        work_space_slug: params.params.actionKey,
        workspace_avatar: params.params.workspaceAvatar,
        visibility: params.params.visibility
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );

    if (createNewWorkspaceResponse.status === 200 || createNewWorkspaceResponse.status === 201) {
      params.params.onSuccess(createNewWorkspaceResponse.data);
      window.showNotification({
        id: Date.now(),
        message: "Workspace has been created successfully.",
        variant: "success",
        autoHide: true,
      });
    }
  } catch (e) {

  }
}

export function* deleteWorkspaceSaga(params) {
  try {

    const deleteWorkspaceResponse = yield axios.delete(
      `${env.newBaseURLDev}/workspaces/workspace/${params.params.id}/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );

    if (deleteWorkspaceResponse.status === 200 || deleteWorkspaceResponse.status === 201 || deleteWorkspaceResponse.status === 204) {
      window.showNotification({
        id: Date.now(),
        message: "Workspace has been deleted successfully.",
        variant: "success",
        autoHide: true,
      });
      params.params.onSuccess(deleteWorkspaceResponse.data);
    }
  } catch (e) {

  }
}

export function* validateWorkspaceNameSaga(params) {
  try {
    const validateWorkspaceNameResponse = yield call(axios.get,
      `${env.newBaseURLDev}/workspaces/workspace/check_conflict?field_type=${params.params.fieldType}&field_name=${params.params.fieldName}&org_id=${params.params.orgId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );

    if (validateWorkspaceNameResponse.status === 200) {
      params.params.onSuccess(validateWorkspaceNameResponse.data);
    }
  } catch (e) {

  }
}

export function* validateActionKeySaga(params) {
  try {

    const validateActionKeyResponse = yield call(axios.get,
      `${env.newBaseURLDev}/workspaces/workspace/validate_action_key`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );

    if (validateActionKeyResponse.status === 200) {
      params.params.onSuccess(validateActionKeyResponse.data);
    }
  } catch (e) {

  }
} 

export function* getAllWorkspaceInvitesSaga(params) {
  try {
    const getAllWorkspaceInvitesResponse = yield call(axios.get,
      `${env.newBaseURLDev}/users/referral/get_all_invites?workspace_id=${params.params.workspaceId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );
    if (getAllWorkspaceInvitesResponse.status === 200) {
      yield put({ type: "SET_ALL_WORKSPACE_INVITES", data: getAllWorkspaceInvitesResponse?.data });
      if(params?.params?.onSuccess){
        params.params.onSuccess(getAllWorkspaceInvitesResponse?.data);
      }
    }
  } catch (e) {
    window.showNotification({
      id: Date.now(),
      message: "Failed to get workspace invites.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* removeWorkspaceInviteSaga(params) {
  try {
    const removeInviteResponse = yield call(axios.put,
      `${env.newBaseURLDev}/users/referral/remove_invite/`,
      {
        referral_id: params.params.referralId
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );

    if (removeInviteResponse.status === 200) {
      yield put({ type: "GET_ALL_WORKSPACE_INVITES", params: { workspaceId: params.params.workspaceId } });
      if (params?.params?.onSuccess) {
        params.params.onSuccess(removeInviteResponse?.data);
      }
      window.showNotification({
        id: Date.now(),
        message: "Invite removed successfully.",
        variant: "success",
        autoHide: true,
      });
    }
  } catch (e) {
    window.showNotification({
      id: Date.now(),
      message: "Failed to remove workspace invite.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* resendWorkspaceInviteSaga(params) {
  try {
    const resendInviteResponse = yield call(axios.put,
      `${env.newBaseURLDev}/users/referral/resend_invite/`,
      {
        referral_id: params.params.referralId,
        referee_email: params.params.refereeEmail
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );

    if (resendInviteResponse.status === 200) {
      if (params?.params?.onSuccess) {
        params.params.onSuccess(resendInviteResponse?.data);
      }
      window.showNotification({
        id: Date.now(),
        message: "Invite resent successfully.",
        variant: "success",
        autoHide: true,
      });
    }
  } catch (e) {
    window.showNotification({
      id: Date.now(),
      message: "Failed to resend workspace invite.",
      variant: "error",
      autoHide: true,
    });
  }
}

