import axios from "axios";
import { put, call } from "redux-saga/effects";
const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* getAllAssistantsSaga(params) {
  try {
    yield put({ type: "SET_ALL_ASSISTANTS_LOADING", data: true });
    let getAssistantResponse = yield axios.get(
      `${env.newBaseURLDev}/assistant/persona/?workspace_id=${params.params.workspace_id}&org_id=${params.params.org_id}`,
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    );
    if (getAssistantResponse.status === 200) {
      yield put({ type: "SET_ALL_ASSISTANTS", data: getAssistantResponse.data});
      yield put({ type: "SET_ALL_ASSISTANTS_LOADING", data: false });
    }
  } catch (error) {
    yield put({ type: "SET_ALL_ASSISTANTS_LOADING", data: false });
    window.showNotification({
        id: Date.now(),
        message: error?.response?.data?.error || "An error occurred while fetching assistants.",
        variant: "error",
        autoHide: true,
      });
  }
}

export function* getHistorySessionsSaga(params) {
      try {
        yield put({ type: "SET_HISTORY_SESSIONS_LOADING", data: true });
        let getHistorySessionsResponse = yield axios.get(
          `${env.newBaseURLDev}/assistant/history?workspace_id=${params.params.workspace_id}&page=${params.params.page || 1}`,
          {
            headers: {
              "Content-Type": "application/json", 
              "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
          } 
        );
        if (getHistorySessionsResponse.status === 200) {
          yield put({ type: "SET_HISTORY_SESSIONS", data: getHistorySessionsResponse.data.results});
          yield put({ type: "SET_HISTORY_SESSIONS_LOADING", data: false });
          if(params.params?.onSuccess){
            params.params?.onSuccess(getHistorySessionsResponse.data.results);
          }
        }
      } catch (error) {
        yield put({ type: "SET_HISTORY_SESSIONS_LOADING", data: false });
        window.showNotification({
            id: Date.now(),
            message: error?.response?.data?.error || "An error occurred while fetching chat history.",
            variant: "error",
            autoHide: true,
          });
      }
}

export function* getSessionHistoryChatSaga(params) {
  try {
    let getSessionHistoryChatResponse = yield axios.get(
      `${env.newBaseURLDev}/assistant/prompts/get_paginated_prompts/?chat_id=${params.params.chat_id}&workspace_id=${params.params.workspace_id}&page_number=${params.params.page || 1}`,
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    );
    if (getSessionHistoryChatResponse.status === 200) {
      console.log("getSessionHistoryChatResponse", getSessionHistoryChatResponse.data);
      yield put({ type: "SET_SESSION_HISTORY_CHAT", data: getSessionHistoryChatResponse.data.results});
      if(params.params?.onSuccess){
      params.params?.onSuccess(getSessionHistoryChatResponse.data.results);
      }
    }
  } catch (error) {
    window.showNotification({
        id: Date.now(),
        message: error?.response?.data?.error || "An error occurred while fetching chat history.",
        variant: "error",
        autoHide: true,
      });
  }
}

export function* sendNewPromptSaga(params) {
  try{
    let sendNewPromptResponse = yield axios.post(
      `${env.newBaseURLDev}/assistant/prompts/prompt/`,
      {
        uid: params.params.uid,
        workspace_id: params.params.workspace_id,
        user_query: params.params.user_query,
        assistant_id: params.params.assistant_id,
        ...(params.params.chat_id && { chat_id: params.params.chat_id }),
        ...params.params.scopeInfo,
        ...(params.params.image_urls?.length > 0 && { image_urls: params.params.image_urls}),
      },
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    );
    if(sendNewPromptResponse.status === 200){
      if(params.params?.onSuccess){
      params.params?.onSuccess(sendNewPromptResponse?.data);
      }
      yield put({
        type: "SET_USED_CREDITS",
        payload: sendNewPromptResponse.data?.data?.counter_obj?.used_credit,
      });
      yield put({
        type: "SET_TOTAL_CREDITS",
        payload: sendNewPromptResponse.data?.data?.counter_obj?.total_credits,
      });
    }else{
      if(params.params?.onError){
      params.params.onError(sendNewPromptResponse?.data);
      }
    }
  } catch (error) {
    if(params.params?.onError){
      params.params?.onError(error);
    }
    
    window.showNotification({
        id: Date.now(),
        message: error?.response?.data?.error || "An error occurred while sending new prompt.",
        variant: "error",
        autoHide: true,
      });
  }
}

export function* generateTicketdetailsSaga(params){
  try{
    let generateTicketdetailsResponse = yield axios.post(
      `${env.newBaseURLDev}/assistant/prompts/generate_ticket/`,
      {
        query: params.params.query,
        workspace_id: params.params.workspace_id,
      },
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    )
    if(generateTicketdetailsResponse.status === 200){
      if(params.params?.onSuccess){
      params.params?.onSuccess(generateTicketdetailsResponse?.data);
      }
      window.showNotification({
        id: Date.now(),
        message: "Ticket details generated successfully",
        variant: "info",
        autoHide: true,
      });
      yield put({
        type: "SET_USED_CREDITS",
        payload: generateTicketdetailsResponse.data?.counter_obj?.used_credit,
      });
      yield put({
        type: "SET_TOTAL_CREDITS",
        payload: generateTicketdetailsResponse.data?.counter_obj?.total_credits,
      });
    }else{
      if(params.params?.onError){
      params.params?.onError(generateTicketdetailsResponse?.data);
      }
    }
  } catch (error) {
    if(params.params?.onError){
      params.params.onError(error);
    }
   
    window.showNotification({
        id: Date.now(),
        message: error?.response?.data?.error || "An error occurred while generating ticket details.",
        variant: "error",
        autoHide: true,
      });
  }
}

export function* regeneratePromptSaga(params){
  try{
    let regeneratePromptResponse = yield axios.post(
      `${env.newBaseURLDev}/assistant/prompts/regenerate/`,
      {
        query: params.params.query,
        workspace_id: params.params.workspace_id,
        assistant_id: params.params.assistant_id,
        chat_id: params.params.chat_id,
      },
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    )
    if(regeneratePromptResponse.status === 200){
      if(params.params?.onSuccess){
      params.params?.onSuccess(regeneratePromptResponse?.data);
      }
      yield put({
        type: "SET_USED_CREDITS",
        payload: regeneratePromptResponse.data?.data?.counter_obj?.used_credit,
      });
      yield put({
        type: "SET_TOTAL_CREDITS",
        payload: regeneratePromptResponse.data?.data?.counter_obj?.total_credits,
      });
    }else{
      if(params.params?.onError){
      params.params.onError(regeneratePromptResponse?.data);
      }
    }
  } catch (error) {
    if(params.params?.onError){
      params.params?.onError(error);
    }
    
    window.showNotification({
        id: Date.now(),
        message: error?.response?.data?.error || "An error occurred while regenerating the response.",
        variant: "error",
        autoHide: true,
      });
  }
}

export function* clearHistorySaga(params){
  try{
    let clearHistoryResponse = yield axios.get(
      `${env.newBaseURLDev}/assistant/prompts/clear_history/?chat_id=${params.params.chat_id}&workspace_id=${params.params.workspace_id}`,
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    )
    if(clearHistoryResponse.status === 200){
      if(params.params?.onSuccess){
        params.params?.onSuccess(clearHistoryResponse?.data);
      }
      window.showNotification({
        id: Date.now(),
        message: "Chat deleted successfully.",
        variant: "success",
        autoHide: true,
      });
    }else{
      window.showNotification({
        id: Date.now(),
        message: "An error occurred while deleting the chat.",
        variant: "error",
        autoHide: true,
      });
    }
  }catch(error){
    window.showNotification({
      id: Date.now(),
      message: "An error occurred while deleting the chat.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* clearAllHistorySaga(params){
  try{
    let clearAllHistoryResponse = yield axios.post(
      `${env.newBaseURLDev}/assistant/history/clear_all_chat/`,
      {
        workspace_id: params.params.workspace_id
      },
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    )
    if(clearAllHistoryResponse.status === 200){
      if(params.params?.onSuccess){
        params.params?.onSuccess(clearAllHistoryResponse?.data);
        yield put({ type: "SET_HISTORY_SESSIONS", data: []});

      }
      window.showNotification({
        id: Date.now(),
        message: "All Chat deleted successfully.",
        variant: "success",
        autoHide: true,
      });
    }else{
      window.showNotification({
        id: Date.now(),
        message: "An error occurred while deleting the chat.",
        variant: "error",
        autoHide: true,
      });
    }
  }catch(error){
    window.showNotification({
      id: Date.now(),
      message: "An error occurred while deleting the chat.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* validateAssistantHandleSaga(params) {
  try {
    const validateAssistantHandleResponse = yield call(axios.get,
      `${env.newBaseURLDev}/assistant/persona/handle_conflict?workspace_id=${params.params.workspaceId}&handle=${params.params.handle}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );

    if (validateAssistantHandleResponse.status === 200) {
      if(params.params?.onSuccess){
        params.params?.onSuccess(validateAssistantHandleResponse.data);
      }
    }else{
      if(params.params?.onError){
        params.params?.onError(validateAssistantHandleResponse?.data);
      }
    }
  } catch (e) {
    if(params.params?.onError){
      params.params?.onError(e);
    }
  }
}

export function* improveWritingSaga(params){
  yield put({ type: "SET_IMPROVE_WRITING_LOADING", payload: true });
  console.log(params.params);
  try{
    let improveWritingResponse = yield axios.post(
      `${env.newBaseURLDev}/assistant/persona/ai_write/`,
      {
        workspace_id: params.params.workspace_id,
        "user_query": params.params.user_query,
        "regenerate_flag": params.params.regenerate_flag
      },
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    )
    if(improveWritingResponse.status === 200){
      if(params.params?.onSuccess){
        params.params?.onSuccess(improveWritingResponse?.data);
        yield put({
          type: "SET_USED_CREDITS",
          payload: improveWritingResponse.data?.data?.counter_obj?.used_credit,
        });
        yield put({
          type: "SET_TOTAL_CREDITS",
          payload: improveWritingResponse.data?.data?.counter_obj?.total_credits,
        });
      }
    }
    yield put({ type: "SET_IMPROVE_WRITING_LOADING", payload: false });
  }catch(error){
    yield put({ type: "SET_IMPROVE_WRITING_LOADING", payload: false });
    window.showNotification({
      id: Date.now(),
      message: error?.response?.data?.error || "An error occurred while improving the writing.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* createAssistantSaga(params){
  try{
    let createAssistantResponse = yield axios.post(
      `${env.newBaseURLDev}/assistant/persona/`,
      {
        "name": params.params.name,
        "handle": params.params.handle,
        "personality": params.params.personality,
        "image_url": params.params.image_url,
        "knowledge_base": params.params.knowledge_base,
        "tasks": params.params.tasks,
        "workspace_id": params.params.workspace_id,
        "description": params.params.description,
      },
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    )
    if(createAssistantResponse.status === 200 || createAssistantResponse.status === 201){
      if(params.params?.onSuccess){
        params.params?.onSuccess(createAssistantResponse?.data);
      }
      yield put({
        type: "GET_ALL_ASSISTANTS",
        params: {
          workspace_id: params.params.workspace_id,
          org_id: JSON.parse(localStorage.getItem("new_org_details")).id
        }
      });
      window.showNotification({
        id: Date.now(),
        message: "New Agent has been created successfully.",
        variant: "success",
        autoHide: true,
      });
    }else{
      if(params.params?.onError){
        params.params?.onError(createAssistantResponse?.data);
      }
    }
  }catch(error){
    window.showNotification({
      id: Date.now(),
      message: error?.response?.data?.error || "An error occurred while creating the assistant.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* updateAssistantSaga(params){
  try{
    let updateAssistantResponse = yield axios.put(
      `${env.newBaseURLDev}/assistant/persona/${params.params.assistant_id}/`,
      {
        "name": params.params.name,
        "handle": params.params.handle,
        "personality": params.params.personality,
        "image_url": params.params.image_url,
        "knowledge_base": params.params.knowledge_base,
        "tasks": params.params.tasks,
        "workspace_id": params.params.workspace_id,
        "description": params.params.description,
      },
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    )
    if(updateAssistantResponse.status === 200 || updateAssistantResponse.status === 201){
      if(params.params?.onSuccess){
        params.params?.onSuccess(updateAssistantResponse?.data);
      }
      yield put({
        type: "GET_ALL_ASSISTANTS",
        params: {
          workspace_id: params.params.workspace_id,
          org_id: JSON.parse(localStorage.getItem("new_org_details")).id
        }
      });
      window.showNotification({
        id: Date.now(),
        message: "Assistant has been updated successfully.",
        variant: "success",
        autoHide: true,
      });
    }else{
      if(params.params?.onError){
        params.params?.onError(updateAssistantResponse?.data);
      }
    }
  }catch(error){
    window.showNotification({
      id: Date.now(),
      message: error?.response?.data?.error || "An error occurred while updating the assistant.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* deleteAssistantSaga(params){
  try{
    let deleteAssistantResponse = yield axios.delete(
      `${env.newBaseURLDev}/assistant/persona/${params.params.assistant_id}/`,
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    )
    if(deleteAssistantResponse.status === 200){
      if(params.params?.onSuccess){
        params.params?.onSuccess(deleteAssistantResponse?.data);
      }
      yield put({
        type: "GET_ALL_ASSISTANTS",
        params: {
          workspace_id: params.params.workspace_id,
          org_id: JSON.parse(localStorage.getItem("new_org_details")).id
        }
      });
      window.showNotification({
        id: Date.now(),
        message: "Assistant deleted successfully.",
        variant: "success",
        autoHide: true,
      });
    }else{
      if(params.params?.onError){
        params.params?.onError(deleteAssistantResponse?.data);
      }
    }
  }catch(error){
    window.showNotification({
      id: Date.now(),
      message: error?.response?.data?.error || "An error occurred while deleting the assistant.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* getAllAssistantTasksSaga(params) {
  try {
    yield put({ type: "SET_ALL_ASSISTANT_TASKS_LOADING", data: true });
    let getAllTasksResponse = yield axios.get(
      `${env.newBaseURLDev}/task/task/get_all_tasks/?workspace_id=${params.params.workspace_id}`,
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    );
    if (getAllTasksResponse.status === 200) {
      yield put({ type: "SET_ALL_ASSISTANT_TASKS", data: getAllTasksResponse.data?.task_details});
      yield put({ type: "SET_ALL_ASSISTANT_TASKS_LOADING", data: false });
    }
  } catch (error) {
    yield put({ type: "SET_ALL_ASSISTANT_TASKS_LOADING", data: false });
    window.showNotification({
        id: Date.now(),
        message: error?.response?.data?.error || "An error occurred while fetching assistant tasks.",
        variant: "error",
        autoHide: true,
      });
  }
}

export function* createAssistantTaskSaga(params){
  try{
    let createAssistantTaskResponse = yield axios.post(
      `${env.newBaseURLDev}/task/task/`,
      {
        title: params.params.title,
        instructions: params.params.instructions,
        image_url: params.params.image_url,
        workspace_id: params.params.workspace_id,
        category_id: params.params.category_id,
      },
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    )
    if(createAssistantTaskResponse.status === 200 || createAssistantTaskResponse.status === 201){
      if(params.params?.onSuccess){
        params.params?.onSuccess(createAssistantTaskResponse?.data);
      }
      yield put({
        type: "GET_ALL_ASSISTANT_TASKS",
        params: {
          workspace_id: params.params.workspace_id
        }
      });
      window.showNotification({
        id: Date.now(),
        message: "New Task has been created successfully.",
        variant: "success",
        autoHide: true,
      });
    }else{
      if(params.params?.onError){
        params.params?.onError(createAssistantTaskResponse?.data);
      }
    }
  }catch(error){
    window.showNotification({
      id: Date.now(),
      message: error?.response?.data?.error || "An error occurred while creating the task.",
      variant: "error",
      autoHide: true,
    });
  }
}


export function* updateAssistantTaskSaga(params){
  try{
    let updateAssistantTaskResponse = yield axios.put(
      `${env.newBaseURLDev}/task/task/${params.params.task_id}/`,
      {
        title: params.params.title,
        instructions: params.params.instructions,
        image_url: params.params.image_url,
        workspace_id: params.params.workspace_id,
        category_id: params.params.category_id,
        tasks: params.params.tasks,
      },
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    )
    if(updateAssistantTaskResponse.status === 200 || updateAssistantTaskResponse.status === 201){
      if(params.params?.onSuccess){
        params.params?.onSuccess(updateAssistantTaskResponse?.data);
      }
      yield put({
        type: "GET_ALL_ASSISTANT_TASKS",
        params: {
          workspace_id: params.params.workspace_id
        }
      });
      window.showNotification({
        id: Date.now(),
        message: "Task has been updated successfully.",
        variant: "success",
        autoHide: true,
      });
    }else{
      if(params.params?.onError){
        params.params?.onError(updateAssistantTaskResponse?.data);
      }
    }
  }catch(error){
    window.showNotification({
      id: Date.now(),
      message: error?.response?.data?.error || "An error occurred while updating the task.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* deleteAssistantTaskSaga(params){
  try{
    let deleteAssistantTaskResponse = yield axios.delete(
      `${env.newBaseURLDev}/task/task/${params.params.task_id}/`,
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    )
    if(deleteAssistantTaskResponse.status === 200 || deleteAssistantTaskResponse.status === 204){
      if(params.params?.onSuccess){
        params.params?.onSuccess(deleteAssistantTaskResponse?.data);
      }

      window.showNotification({
        id: Date.now(),
        message: "Task deleted successfully.",
        variant: "success",
        autoHide: true,
      });
    }else{
      if(params.params?.onError){
        params.params?.onError(deleteAssistantTaskResponse?.data);
      }
    }
    yield put({
      type: "GET_ALL_ASSISTANT_TASKS",
      params: {
        workspace_id: params.params.workspace_id
      }
    });
  }catch(error){
    window.showNotification({
      id: Date.now(),
      message: error?.response?.data?.error || "An error occurred while deleting the task.",
      variant: "error",
      autoHide: true,
    });
  }
}

export function* uploadAssistantAudioSaga(params) {
  try {
    let uploadAudioResponse = yield axios.post(
      `${env.newBaseURLDev}/media/upload_audio/`,
      {
        workspace_id: params.params.workspace_id,
        audio_file_url: params.params.audio_file_url,
        status: params.params.status,
        parent_id: params.params.parent_id
      },
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      } 
    );
    
    if (uploadAudioResponse.status === 200 || uploadAudioResponse.status === 201) {
      if (params.params?.onSuccess) {
        params.params?.onSuccess(uploadAudioResponse?.data);
      }
      window.showNotification({
        id: Date.now(),
        message: "Audio file uploaded successfully.",
        variant: "success",
        autoHide: true,
      });
    } else {
      if (params.params?.onError) {
        params.params?.onError(uploadAudioResponse?.data);
      }
    }
  } catch (error) {
    if (params.params?.onError) {
      params.params?.onError(error);
    }
    window.showNotification({
      id: Date.now(),
      message: error?.response?.data?.error || "An error occurred while uploading the audio file.",
      variant: "error",
      autoHide: true,
    });
  }
}

