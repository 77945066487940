import { put } from "redux-saga/effects";
import axios from "axios";
import {
  SET_HOME_FEED,
  SET_HOME_FEED_LOADING
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* getHomeFeedSaga(params) {
  yield put({
    type: SET_HOME_FEED_LOADING,
    payload: true,
  });
  try {
      let getHomeFeedResponse = yield axios.post(
          `${env.newBaseURLDev}/workspaces/feed/get_home_feed/`,
          {
            "workspace_id": params.params.workspace_id,
            "board_id": params.params.board_id,
            "card_types": params.params.card_types
          },
          {
              headers: {
                "Content-Type": "application/json", 
                "Authorization": `Bearer ${localStorage.getItem("token")}`
              },
          }
      );
      if(getHomeFeedResponse.status === 200){
        yield put({
          type: SET_HOME_FEED,
          payload: getHomeFeedResponse.data,
        });
        yield put({
          type: SET_HOME_FEED_LOADING,
          payload: false,
        });
      }
  } catch (error) {
    yield put({
      type: SET_HOME_FEED_LOADING,
      payload: false,
    });
  }   
}


export function* getHomeFeedFiltersSaga(params) {
  try {
    const getHomeFeedFiltersResponse = yield axios.get(
      `${env.newBaseURLDev}/users/user-meta/get_user_meta/`,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    if(getHomeFeedFiltersResponse.status === 200){
      if(params?.params?.onSuccess){
        params.params.onSuccess(getHomeFeedFiltersResponse.data?.home_page_config?.home_page_order ?? []);
      }
      yield put({
        type: "SET_HOME_FEED_FILTERS",
        payload: getHomeFeedFiltersResponse.data?.home_page_config?.home_page_order ?? [],
      });
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export function* updateHomeFeedFiltersSaga(params) {
  try {
    const updateHomeFeedFiltersResponse = yield axios.post(
      `${env.newBaseURLDev}/users/user-meta/home_page_config/`,
      {
        home_page_config : {home_page_order: params.params.card_types}
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    if(updateHomeFeedFiltersResponse.status === 200){
      if(params?.params?.onSuccess){
        params.params.onSuccess();
      }
    }
    yield put({
      type: "SET_HOME_FEED_FILTERS",
      payload: params.params.card_types ?? [],
    });
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export function* markOnboardingStepSaga(params) {
  try {
    const markOnboardingStepResponse = yield axios.post(
      `${env.newBaseURLDev}/workspaces/stepper/`,
      {
        user_id: params.params.user_id,
        workspace_id: params.params.workspace_id,
        step_type: params.params.entity_type
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    if (params?.params?.onSuccess) {
      params.params.onSuccess(markOnboardingStepResponse.data);
    }
    return markOnboardingStepResponse.data;
  } catch (error) {
    console.error("Error marking onboarding step:", error);
    if (params?.params?.onError) {
      params.params.onError(error);
    }
    throw error;
  }
}

