import axios from "axios";
import { put } from "redux-saga/effects";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* getAppIntegrationsSaga(params) {
  try {
      let appIntegrationsResponse = yield axios.get(
          `${env.newBaseURLDev}/integrations/user-integrations/list_user_integrations/?workspace_id=${params.params.workspace_id}`,
          {
              headers: {
                "Content-Type": "application/json", 
                "Authorization": `Bearer ${localStorage.getItem("token")}`
              },
          }
      );
      yield put({ type: "SET_APP_INTEGRATIONS", params: appIntegrationsResponse.data });
  } catch (error) {
    console.log(error);
  }   
}

export function* updateAppIntegrationsSaga(params) {
  try {
    let updateAppIntegrationsResponse = yield axios.post(
      `${env.newBaseURLDev}/integrations/user-integrations/enable_integration/`,
      {
        "workspace_id": params.params.workspace_id,
        "integration_id": params.params.integration_id,
        "ext_token": params.params.ext_token,
        "is_enabled": params.params.is_enabled,
        "meta": params.params.meta
      },
      {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    if(updateAppIntegrationsResponse.status === 200  || updateAppIntegrationsResponse.status === 201){

      yield put({ type: "UPDATE_APP_INTEGRATIONS_STATE", params: updateAppIntegrationsResponse.data });

      if (params.params?.is_enabled) {
      window.showNotification({
        id: Date.now(),
        message: "Integration enabled successfully.",
        variant: "success",
        autoHide: true,
      });
    }else{
      window.showNotification({
        id: Date.now(),
        message: "Integration disabled successfully.",
        variant: "success",
        autoHide: true,
      });
      }
    }
  } catch (error) {
    console.log(error);
  }
}