import { ALL_NOTIFICATIONS_READ, GET_ALL_NOTIFICATIONS, UPDATE_READ_STATUS } from './types';

export const getAllNotificationsAction = params => ({
  type: GET_ALL_NOTIFICATIONS,
  params: params,
});

export const updateReadStatusAction = data => ({
  type: UPDATE_READ_STATUS,
  params: data,
});

export const allNotificationsReadAction = () => ({
  type: ALL_NOTIFICATIONS_READ,
});

export const insertNotificationAction = params => ({
  type: 'INSERT_NOTIFICATION',
  params: params,
});

export const getNotificationSettingsAction = params => ({
  type: 'GET_NOTIFICATION_SETTINGS',
  params: params,
});

export const updateNotificationSettingsAction = params => ({
  type: 'UPDATE_NOTIFICATION_SETTINGS',
  params: params,
});
