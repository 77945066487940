import {
  GET_SITE_MEMBERS,
  GET_SITE_OWNER,
  DELETE_TASK,
  SAVE_TIME_LOG,
  GET_TASKS,
  RESET_TASKS,
  CREATE_NEW_TASK
} from "../actions/types";

export const createNewTaskAction = (data) => ({
  type: CREATE_NEW_TASK,
  params: data,
});

export const getSiteMembersAction = (data) => ({
  type: GET_SITE_MEMBERS,
  params: data,
});

export const getSiteOwnerAction = (data) => ({
  type: GET_SITE_OWNER,
  params: data,
});

export const deleteTaskAction = (data) => ({
  type: DELETE_TASK,
  params: data,
});

export const saveTimeLogAction = (data) => ({
  type: SAVE_TIME_LOG,
  params: data,
});

export const getTasksAction = (data) => ({
  type: GET_TASKS,
  params: data,
});

export const resetTasksAction = () => ({
  type: RESET_TASKS,
});

export const getActionActivitiesAction = (data) => ({
  type: "GET_ACTION_ACTIVITIES",
  params: data,
});