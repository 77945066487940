const initialState = {
  integrations: []
};

export default function appIntegrationsReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_APP_INTEGRATIONS":
      return {
        ...state,
        integrations: action.params,
      };
    case "UPDATE_APP_INTEGRATIONS_STATE":
      console.log("action.params",action.params);
      return {
        ...state,
        integrations: state.integrations.map((integration) =>
          integration.id === action.params.integration_id ? {...integration, is_connected: !action.params.is_disabled,user_integration_detils: action.params} : integration
        ),
      };
    default:
      return state;
  }
};
