// import React from 'react';
// import { Helmet } from 'react-helmet-async';
// export default function SEO({ title, description, name, type ,image}) {
//     // Get the website URL from window location or use your domain
//     const siteUrl = typeof window !== 'undefined' ? window.location.origin : 'https://test.permi.tech';
//     const defaultImage = `${siteUrl}/favicon.ico`;

//     return (
//         <Helmet>
//             { /* Standard metadata tags */}
//             <title>{title}</title>
//             <meta name='description' content={description} data-react-helmet="true"/>
//             <meta charset="utf-8" data-react-helmet="true"/>
//             <meta name="viewport" content="width=device-width, initial-scale=1" data-react-helmet="true"/>
//             { /* End standard metadata tags */}
//             { /* Facebook tags */}
//             <meta property="og:url" content={siteUrl} data-react-helmet="true"/>
//             <meta property="og:type" content={type} data-react-helmet="true"/>
//             <meta property="og:title" content={title} data-react-helmet="true"/>
//             <meta property="og:description" content={description} data-react-helmet="true"/>
//             <meta property="og:image" content={image || defaultImage} data-react-helmet="true"/>
//             { /* End Facebook tags */}
//             { /* Twitter tags */}
//             <meta name="twitter:creator" content={name} data-react-helmet="true"/>
//             <meta name="twitter:card" content={type} data-react-helmet="true"/>
//             <meta name="twitter:title" content={title} data-react-helmet="true"/>
//             <meta name="twitter:description" content={description} data-react-helmet="true"/>
//             <meta property="twitter:image" content={image || defaultImage} data-react-helmet="true"/>
//             { /* End Twitter tags */}
//             { /* Slack tags */}
//             <meta property="slack:creator" content={name} data-react-helmet="true"/>
//             <meta property="slack:title" content={title} data-react-helmet="true"/>
//             <meta property="slack:description" content={description} data-react-helmet="true"/>
//             <meta property="slack:image" content={image || defaultImage} data-react-helmet="true"/>
//             { /* End Slack tags */}
//             { /* WhatsApp tags */}
//             <meta property="whatsapp:title" content={title} data-react-helmet="true"/>
//             <meta property="whatsapp:description" content={description} data-react-helmet="true"/>
//             <meta property="whatsapp:image" content={image || defaultImage} data-react-helmet="true"/>
//             { /* End WhatsApp tags */}
//             { /* LinkedIn tags */}
//             <meta property="linkedin:title" content={title} data-react-helmet="true"/>
//             <meta property="linkedin:description" content={description} data-react-helmet="true"/>
//             <meta property="linkedin:image" content={image || defaultImage} data-react-helmet="true"/>
//             { /* End LinkedIn tags */}
//         </Helmet>
//     )
// }

import { Helmet } from "react-helmet-async";

export default function SEO({ title, description, name, type, image }) {
  // Get the website URL from window location or use your domain
  const siteUrl = typeof window !== 'undefined' ? window.location.origin : 'https://test.permi.tech';
  const defaultImage = `${siteUrl}/favicon.ico`;

  return (
    <Helmet prioritizeSeoTags>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Facebook tags */}
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image || defaultImage} />

      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image || defaultImage} />

      {/* LinkedIn specific tags */}
      <meta property="linkedin:title" content={title} />
      <meta property="linkedin:description" content={description} />
      <meta property="linkedin:image" content={image || defaultImage} />
    </Helmet>
  );
}
