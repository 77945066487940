import {
    GET_HOME_FEED,
    SET_HOME_FEED_LOADING,
  } from "../actions/types";

  
  export const getHomeFeedAction = (data) => ({
    type: GET_HOME_FEED,
    params: data,
  });

  export const setHomeFeedLoadingAction = (data) => ({
    type: SET_HOME_FEED_LOADING,
    payload: data,
  });

  export const getHomeFeedFiltersAction = (data) => ({
    type: "GET_HOME_FEED_FILTERS",
    params: data,
  });

  export const updateHomeFeedFiltersAction = (data) => ({
    type: "UPDATE_HOME_FEED_FILTERS",
    params: data,
  });

  export const markOnboardingStepAction = (data) => ({
    type: "MARK_ONBOARDING_STEP",
    params: data,
  });
