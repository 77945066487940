import {
    SET_WORKSPACE_ID,
    SET_ORG_ID,
    SET_BOARD_ID,
  } from "../actions/types";
  
 export const setWorkspaceIdAction = (data) => ({
    type: SET_WORKSPACE_ID,
    payload: data,
  });
  
  export const setOrgIdAction = (data) => ({
    type: SET_ORG_ID,
    payload: data,
  });
  
  export const setBoardIdAction = (data) => ({
    type: SET_BOARD_ID,
    payload: data,
  });

  export const toggleConfettiAction = (data) => ({
    type: "TOGGLE_CONFETTI",
    payload: data,
  });
  export const interaction = (data) => ({
    type: "INTERACTION",
    params: data,
  });

  export const setHelperTabAction = (data) => ({
    type: "SET_HELPER_TAB",
    payload: data,
  });

  export const setHelperFiltersAction = (data) => ({
    type: "SET_HELPER_FILTERS",
    payload: data,
  });

  export const getInfoBannerAction = (data) => ({
    type: "GET_INFO_BANNER",
    params: data,
  }); 

  export const setSpacesFiltersAction = (data) => ({
    type: "SET_SPACES_FILTERS",
    payload: data,
  });
  
  export const setDocumentsFiltersAction = (data) => ({
    type: "SET_DOCUMENTS_FILTERS",
    payload: data,
  });

  export const setTeamManagementFiltersAction = (data) => ({
    type: "SET_TEAM_MANAGEMENT_FILTERS",
    payload: data,
  });
