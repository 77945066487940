import axios from 'axios';
import { put } from 'redux-saga/effects';
import {
  SET_ALL_NOTIFICATIONS,
  SET_ALL_UNREAD_NOTIFICATIONS_COUNT,
  SHOW_TOAST,
  GET_ALL_NOTIFICATIONS,
} from '../actions/types';

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* getAllNotificationsSaga(params) {
  try {
    if (params.params?.page === 1) {
      yield put({
        type: 'NOTIFICATIONS_LOADING',
        data: true,
      });
    }

    let getAllNotificationsResponse = yield axios.get(
      `${env.newBaseURLDev}/notifications/notification?workspace_id=${JSON.parse(localStorage.getItem("new_workspace")).id}&page=${params.params.page}&tab_type=${params.params.tab_type || 'all'}&page_size=20`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    if (getAllNotificationsResponse.status === 200) {
      yield put({
        type: SET_ALL_NOTIFICATIONS,
        data: getAllNotificationsResponse.data,
      });
      yield put({
        type: SET_ALL_UNREAD_NOTIFICATIONS_COUNT,
        data: getAllNotificationsResponse.data.unread_count,
      });
      yield put({
        type: 'NOTIFICATIONS_LOADING',
        data: false,
      });
      if (params.params?.onSuccess) {
        params.params.onSuccess(getAllNotificationsResponse.data);
      }
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: 'Something went wrong, please try again later.',
        toastType: 0,
      },
    });
  }
}

export function* updateReadStatusSaga(params) {
  console.log(params, 'params');
  try {
    let reqObj = {};
    if (params.params === 'all') {
      reqObj = {
        mark_all: true,
      };
    } else {
      reqObj = {
        notification_id: params.params.notification_id,
      };
    }

    let updateReadStatusResponse = yield axios.put(
      `${env.newBaseURLDev}/notifications/notification/mark_notification_inactive/`,
      reqObj,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    if (updateReadStatusResponse.status === 200) {
      yield put({
        type: GET_ALL_NOTIFICATIONS,
        params: {
          page: 1,
          tab_type: 'all',
        },
      });
    }
  } catch {
    window.showNotification({
      id: Date.now(),
      message: 'Something went wrong, please try again later.',
      variant: 'error',
      autoHide: true,
    });
  }
}
export function* allNotificationsReadSaga(params) {
  try {
    let reqObj = {
      mark_all: true,
    };
    yield axios.put(
      `${env.newBaseURLDev}/notifications/notification/mark_notification_inactive/`,
      reqObj,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: 'Something went wrong, please try again later.',
        toastType: 0,
      },
    });
  }
}

export function* insertNotificationSaga(params) {
  try {
    const response = yield axios.post(
      `${env.newBaseURLDev}/notifications/notification/insert_notification/`,
      {
        notification_type: params.params.notification_type,
        workspace_id: params.params.workspace_id,
        board_id: params.params.board_id,
        meta: params.params.meta,
        related_action_id: params.params.related_action_id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (response.status === 200 && params.params?.onSuccess) {
      params.params.onSuccess(response.data);
    }
  } catch {
    window.showNotification({
      id: Date.now(),
      message: 'Failed to insert notification.',
      variant: 'error',
      autoHide: true,
    });
  }
}

export function* getNotificationSettingsSaga(params) {
  console.log(params, 'params');
  try {
    const response = yield axios.get(
      `${env.newBaseURLDev}/notifications/notification/notification_settings`,
      {
        params: {
          workspace_id: params.params.workspace_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    if (response.status === 200) {
      yield put({
        type: 'SET_NOTIFICATION_SETTINGS',
        data: response.data?.data?.notification_management_screen,
      });
      if (params.params?.onSuccess) {
        params.params.onSuccess(response.data);
      }
    }
  } catch (error) {
    window.showNotification({
      id: Date.now(),
      message: 'Failed to fetch notification settings.',
      variant: 'error',
      autoHide: true,
    });
  }
}

export function* updateNotificationSettingsSaga(params) {
  try {
    const response = yield axios.post(
      `${env.newBaseURLDev}/notifications/notification/update_notification_settings/`,
      {
        workspace_id: params.params.workspace_id,
        notification_meta_id: params.params.notification_meta_id,
        entity_type: params.params.entity_type,
        is_active: params.params.is_active,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    if (response.status === 200) {
      // yield getNotificationSettingsSaga(params);
    }
  } catch {
    window.showNotification({
      id: Date.now(),
      message: 'Failed to update notification settings.',
      variant: 'error',
      autoHide: true,
    });
  }
}

// export function* allNotificationsReadSaga() {
//   try {
//     let updateReadStatusResponse = yield axios.get(
//       `${env.baseURLDev}/notifications/update-all-read-status`,
//       {
//         headers: {
//           authorization: `${localStorage.getItem("token")}`,
//         },
//       }
//     );

//     if (updateReadStatusResponse.status === 200) {
//       // yield put({type: SET_ALL_NOTIFICATIONS, data: updateReadStatusResponse.data.data})
//     }
//   } catch {
//     yield put({
//       type: SHOW_TOAST,
//       data: {
//         message: "Something went wrong, please try again later.",
//         toastType: 0,
//       },
//     });
//   }
// }
