import { useState, useEffect, useCallback } from "react";

const useToastAlert = (
  initialMessage = "",
  initialToastType = null,
  initialToastId = null,
  initialAutoHide = true
) => {
  const [notifications, setNotifications] = useState([]);
  const [message, setMessage] = useState(initialMessage);
  const [toastType, setToastType] = useState(initialToastType);
  const [toastId, setToastId] = useState(initialToastId);
  const [autoHide, setAutoHide] = useState(initialAutoHide);

  const hideNotification = useCallback((notificationId) => {
    setNotifications((prev) => prev.filter(({ id }) => id !== notificationId));
  }, []);

  const showNotification = useCallback(
    (newNotification) => {
      setNotifications((prev) => {
        const notificationIndex = prev.findIndex(
          ({ id }) => id === newNotification.id
        );
        if (notificationIndex > -1) {
          const existingNotification = prev[notificationIndex];
          if (
            existingNotification.message === newNotification.message &&
            existingNotification.variant === newNotification.variant
          ) {
            return prev;
          } else {
            const updatedNotifications = [...prev];
            updatedNotifications.splice(notificationIndex, 1, newNotification);
            return updatedNotifications;
          }
        } else {
          return [...prev, newNotification];
        }
      });

      if (newNotification.autoHide) {
        const delay = newNotification.variant === "error" ? 5000 : 3000;
        setTimeout(() => {
          hideNotification(newNotification.id);
        }, delay);
      }
    },
    [hideNotification]
  );

  const notify = useCallback(() => {
    if (toastType !== null) {
      const notificationConfig = {
        id: toastId ?? Date.now(),
        message,
        variant:
          toastType === 0 ? "error" : toastType === 1 ? "success" : "primary",
        autoHide,
      };
      showNotification(notificationConfig);
    }
  }, [toastType, toastId, message, autoHide, showNotification]);

  useEffect(() => {
    if (toastType !== null) {
      notify();
    }
  }, [toastType, notify]);

  return {
    notifications,
    showNotification,
    hideNotification,
    setMessage,
    setToastType,
    setToastId,
    setAutoHide,
  };
};

export default useToastAlert;
