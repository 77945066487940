const initialState = {
    allAssistants: [],
    allAssistantsLoading: false,
    selectedAssistant: null,
    historySessions: [],
    historySessionsLoading: false,
    sessionHistoryChat: [],
    allAssistantTasks: [],
    allAssistantTasksLoading: false,
    assistantMessages: [],
    assistantChatSessionId: null
}

export default function assistantReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_ALL_ASSISTANTS":
            return { ...state, allAssistants: action.data };
        case "SET_ALL_ASSISTANTS_LOADING":
            return { ...state, allAssistantsLoading: action.data };
        case "SET_SELECTED_ASSISTANT":
            return { ...state, selectedAssistant: action.params };
        case "SET_HISTORY_SESSIONS":
            return { ...state, historySessions: action.data };
        case "SET_HISTORY_SESSIONS_LOADING":
            return { ...state, historySessionsLoading: action.data };
        case "SET_SESSION_HISTORY_CHAT":
            return { ...state, sessionHistoryChat: action.data };
        case "SET_ALL_ASSISTANT_TASKS":
            return { ...state, allAssistantTasks: action.data };
        case "SET_ALL_ASSISTANT_TASKS_LOADING":
            return { ...state, allAssistantTasksLoading: action.data };
        case "SET_ASSISTANT_MESSAGES":
            return { ...state, assistantMessages: action.params };
        case "SET_ASSISTANT_CHAT_SESSION_ID":
            return { ...state, assistantChatSessionId: action.params };
        default:
            return state;
    }
}