export const updateWorkspaceDetails = (details) => ({
  type: "UPDATE_WORKSPACE_DETAILS",
  details,
});
export const deleteWorkspaceAction = (params) => ({
  type: "DELETE_WORKSPACE",
  params:params,
});
export const getAllWorkspacesAction = (params) => ({
  type: "GET_ALL_WORKSPACES",
  params : params,
});
export const createNewWorkspaceAction = (params) => ({
  type: "CREATE_NEW_WORKSPACE",
  params : params,
});
export const validateWorkspaceNameAction = (params) => ({
  type: "VALIDATE_WORKSPACE_NAME",
  params : params,
});
export const validateActionKeyAction = (params) => ({
  type: "VALIDATE_ACTION_KEY",
  params : params,
});
export const getPublicWorkspaceDetailsAction = (params) => ({
  type: "GET_PUBLIC_WORKSPACE_DETAILS",
  params : params,
});
export const getAllWorkspaceInvitesAction = (params) => ({
  type: "GET_ALL_WORKSPACE_INVITES",
  params : params,
});
export const resendWorkspaceInviteAction = (params) => ({
  type: "RESEND_WORKSPACE_INVITE",
  params : params,
});
export const removeWorkspaceInviteAction = (params) => ({
  type: "REMOVE_WORKSPACE_INVITE",
  params : params,
});
