import {
  ALL_NOTIFICATIONS_READ,
  SET_ALL_NOTIFICATIONS,
  SET_ALL_UNREAD_NOTIFICATIONS_COUNT
} from "../actions/types";

const initialState = {
  paginatedNotifications: null,
  unreadNotificationsCount: 0,
  notificationsLoading: false,
  notificationSettings: {}
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_NOTIFICATIONS:
      return {
        ...state,
        paginatedNotifications: action.data,
      };

    case "NOTIFICATIONS_LOADING":
      return {
        ...state,
        notificationsLoading: action.data,
      };

    case ALL_NOTIFICATIONS_READ:
      let temp = [...state.paginatedNotifications?.notifications];
      temp.forEach((t) => {
        t.notification_read = 1;
      });
      return {
        ...state,
        paginatedNotifications: {...state.paginatedNotifications, notifications: temp},
      };

    case SET_ALL_UNREAD_NOTIFICATIONS_COUNT:
      return {
        ...state,
        unreadNotificationsCount: action.data,
      };

    case "SET_NOTIFICATION_SETTINGS":
      return {
        ...state,
        notificationSettings: action.data,
      };

    default:
      return state;
  }
}
