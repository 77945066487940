import { all, takeLatest } from "redux-saga/effects";
import {
  SIGN_UP,
  LOG_IN,
  ADD_NEW_SITE,
  GET_ALL_SITES,
  ADD_NEW_BOARD,
  GET_ISSUE_TYPES,
  CREATE_NEW_TASK,
  REDIRECT_TO_BOARD,
  CREATE_NEW_DASHBOARD,
  UPDATE_DASHBOARD_NAME_DESCRIPTION,
  MOVE_TO_TRASH,
  DUPLICATE_THIS_DASHBOARD,
  ADD_CARD_TO_BOARD,
  GET_ALL_CARDS,
  GET_KPI_METRIC,
  ARCHIVE_CARD,
  UPDATE_READ_STATUS,
  ALL_NOTIFICATIONS_READ,
  GET_ALL_NOTIFICATIONS,
  UPDATE_VIEWERS,
  GET_SHARE_KEY,
  CONFIRM_SHARE,
  GET_ALL_VIEW_CARDS,
  UPDATE_SITE_AVATAR,
  UPDATE_SITE_DETAILS,
  DELETE_WORKSPACE,
  CREATE_NEW_ISSUE,
  GET_DASHBOARD_COUNT,
  GET_TASKS,
  GET_SEARCH_RESULTS,
  GET_HOME_FEED,
  GET_ALL_BOARD_ENTITY,
  GET_BOARD_MEMBERS,
  GET_SPACES_DATA,
  CREATE_STRIPE_SESSION,
  GET_STRIPE_TRANSACTION_STATUS,
  GET_STRIPE_PLANS,
  GET_CURRENT_PLAN,
  GET_MARKETPLACE_DATA,
  GET_REPORTS,
} from "../actions/types";
import {
  signUpSaga,
  logInSaga,
  checkInvitedOrganisationsSaga,
} from "./authSagas";
import {
  addNewSiteSaga,
  getAllSitesSaga,
  // getAccessSitesSaga,
  // leaveSiteSaga,
  // revokeAccessToSiteSaga,
  updateSiteAvatarSaga,
  updateSiteDetailsSaga,
  // updateManHoursSaga,
  // getWorkspaceManHoursSaga,
  getDashboardCountSaga,
  // getCombinedSitesSaga,
} from "./siteSagas";
import {
  addNewBoardSaga,
  // getAllBoardsSaga,
  redirectToBoardSaga,
  getAllBoardEntitySaga,
  getBoardMembersSaga,
  customiseBoardEntitySaga,
  deleteBoardEntitySaga,
  createNewIssuePrioritySaga,
  createNewIssueStatusSaga,
  reorderBoardEntitySaga,
  removeBoardMemberSaga,
  getBoardMembersRolesSaga,
  updateBoardMembersRolesSaga,
} from "./boardSagas";
import {
  // archiveIssueTypeSaga,
  createNewIssueTypeSaga,
  getIssueTypesSaga,
} from "./issueTypesSagas";
import {
  createNewTaskSaga,
  getTasksSaga,
  getActionActivitiesSaga,
} from "./taskSagas";
import {
  addCardToBoardSaga,
  confirmShareSaga,
  createNewDashboardSaga,
  duplicateThisDashboardSaga,
  // getAllAccessDashboardsSaga,
  getAllCardsSaga,
  // getAllDashboardsSaga,
  getAllViewCardsSaga,
  getShareKeySaga,
  moveToTrashSaga,
  updateDashboardNameDescriptionSaga,
  updateViewersSaga,
} from "./dashboardSagas";
import { archiveCardSaga, geteKPIMetricSaga } from "./KPISagas";
import {
  getUserMetaSaga,
  updateUserMetaSaga,
  updateUserInfoSaga,
} from "./userMetaSagas";
import {
  allNotificationsReadSaga,
  getAllNotificationsSaga,
  updateReadStatusSaga,
  insertNotificationSaga,
  getNotificationSettingsSaga,
  updateNotificationSettingsSaga,
} from "./notificationsSagas";
import {
  getSpacesDataSaga,
  requestDocumentAccessSaga,
  accessProvidedSaga,
  createSpacesEntitySaga,
  getFolderDocumentsSaga,
  renameSpacesEntitySaga,
  deleteSpacesEntitySaga,
  updateDocumentEntitySaga,
  getAllFolderDocumentsSaga,
} from "./spacesSagas";
import {
  getMarketplaceDataSaga,
  runAgentSaga,
  addAgentSaga,
  removeAgentSaga,
  getAgentJobDetailsSaga,
  getAgentHistorySaga,
  getAddedAgentsSaga,
} from "./marketplaceSagas";
import { getSearchResultsSaga } from "./searchSagas";
import {
  getHomeFeedSaga,
  getHomeFeedFiltersSaga,
  updateHomeFeedFiltersSaga,
  markOnboardingStepSaga,
} from "./homeSagas";
import { updateOrganisationSaga } from "./organisationSagas";
import {
  createStripeSessionSaga,
  getCurrentPlanSaga,
  getStripePlansSaga,
  getStripeTransactionStatusSaga,
  getLaunchConfigSaga,
} from "./stripeSagas";
import { getReportsSaga } from "./reportSagas";
import {
  gettextFromDocSaga,
  getTextToSpaceSaga,
  updateEditAccessSaga,
} from "./utilSagas";
import {
  handleWorkspaceDetailsUpdateSaga,
  deleteWorkspaceSaga,
  getAllWorkspacesSaga,
  createNewWorkspaceSaga,
  validateWorkspaceNameSaga,
  validateActionKeySaga,
  getPublicWorkspaceDetailsSaga,
  getAllWorkspaceInvitesSaga,
  resendWorkspaceInviteSaga,
  removeWorkspaceInviteSaga,
} from "./workspaceSagas";
import {
  getAllAssistantsSaga,
  getHistorySessionsSaga,
  getSessionHistoryChatSaga,
  sendNewPromptSaga,
  generateTicketdetailsSaga,
  regeneratePromptSaga,
  clearHistorySaga,
  clearAllHistorySaga,
  validateAssistantHandleSaga,
  improveWritingSaga,
  createAssistantSaga,
  deleteAssistantSaga,
  updateAssistantSaga,
  updateAssistantTaskSaga,
  deleteAssistantTaskSaga,
  getAllAssistantTasksSaga,
  createAssistantTaskSaga,
  uploadAssistantAudioSaga,
} from "./assistantSagas";
import { interactionSaga, infoBannerSaga } from "./appSagas";
import { getAppIntegrationsSaga, updateAppIntegrationsSaga } from "./appIntegrationsSagas";
export default function* root() {
  const sagaIndex = [
    yield takeLatest(GET_SPACES_DATA, getSpacesDataSaga),
    yield takeLatest(SIGN_UP, signUpSaga),
    yield takeLatest(LOG_IN, logInSaga),
    yield takeLatest(
      "CHECK_INVITED_ORGANISATIONS",
      checkInvitedOrganisationsSaga
    ),
    yield takeLatest(ADD_NEW_SITE, addNewSiteSaga),
    yield takeLatest(GET_ALL_SITES, getAllSitesSaga),
    // yield takeLatest(GET_ACCESS_SITES, getAccessSitesSaga),
    yield takeLatest(ADD_NEW_BOARD, addNewBoardSaga),
    // yield takeLatest(GET_ALL_BOARDS, getAllBoardsSaga),
    yield takeLatest(REDIRECT_TO_BOARD, redirectToBoardSaga),
    yield takeLatest(GET_ISSUE_TYPES, getIssueTypesSaga),
    yield takeLatest(CREATE_NEW_ISSUE, createNewIssueTypeSaga),
    // yield takeLatest(ARCHIVE_ISSUE_TYPE, archiveIssueTypeSaga),
    yield takeLatest(CREATE_NEW_TASK, createNewTaskSaga),
    // yield takeLatest(LEAVE_SITE, leaveSiteSaga),
    yield takeLatest(CREATE_NEW_DASHBOARD, createNewDashboardSaga),
    yield takeLatest(
      UPDATE_DASHBOARD_NAME_DESCRIPTION,
      updateDashboardNameDescriptionSaga
    ),
    yield takeLatest(DUPLICATE_THIS_DASHBOARD, duplicateThisDashboardSaga),
    yield takeLatest(MOVE_TO_TRASH, moveToTrashSaga),
    yield takeLatest(UPDATE_VIEWERS, updateViewersSaga),
    yield takeLatest(GET_SHARE_KEY, getShareKeySaga),
    yield takeLatest(CONFIRM_SHARE, confirmShareSaga),
    yield takeLatest(GET_ALL_CARDS, getAllCardsSaga),
    yield takeLatest(GET_ALL_VIEW_CARDS, getAllViewCardsSaga),
    yield takeLatest(ADD_CARD_TO_BOARD, addCardToBoardSaga),
    yield takeLatest(GET_KPI_METRIC, geteKPIMetricSaga),
    yield takeLatest(ARCHIVE_CARD, archiveCardSaga),
    yield takeLatest(GET_ALL_NOTIFICATIONS, getAllNotificationsSaga),
    yield takeLatest(UPDATE_READ_STATUS, updateReadStatusSaga),
    yield takeLatest(ALL_NOTIFICATIONS_READ, allNotificationsReadSaga),
    yield takeLatest(UPDATE_SITE_AVATAR, updateSiteAvatarSaga),
    yield takeLatest(UPDATE_SITE_DETAILS, updateSiteDetailsSaga),
    // yield takeLatest(UPDATE_MAN_HOURS, updateManHoursSaga),
    // yield takeLatest(GET_WORKSPACE_MAN_HOURS, getWorkspaceManHoursSaga),
    yield takeLatest(GET_DASHBOARD_COUNT, getDashboardCountSaga),
    yield takeLatest(GET_TASKS, getTasksSaga),
    yield takeLatest(GET_SEARCH_RESULTS, getSearchResultsSaga),
    yield takeLatest(GET_HOME_FEED, getHomeFeedSaga),
    yield takeLatest(GET_ALL_BOARD_ENTITY, getAllBoardEntitySaga),
    yield takeLatest(GET_BOARD_MEMBERS, getBoardMembersSaga),
    yield takeLatest("CREATE_SPACES_ENTITY", createSpacesEntitySaga),
    yield takeLatest("GET_FOLDER_DOCUMENTS", getFolderDocumentsSaga),
    yield takeLatest("GET_ALL_FOLDER_DOCUMENTS", getAllFolderDocumentsSaga),
    yield takeLatest("RENAME_SPACES_ENTITY", renameSpacesEntitySaga),
    yield takeLatest("DELETE_SPACES_ENTITY", deleteSpacesEntitySaga),
    yield takeLatest("UPDATE_DOCUMENT_ENTITY", updateDocumentEntitySaga),
    yield takeLatest(GET_MARKETPLACE_DATA, getMarketplaceDataSaga),
    yield takeLatest("RUN_AGENT", runAgentSaga),
    yield takeLatest("ADD_AGENT", addAgentSaga),
    yield takeLatest("REMOVE_AGENT", removeAgentSaga),
    yield takeLatest("GET_AGENT_JOB_DETAILS", getAgentJobDetailsSaga),
    yield takeLatest("GET_AGENT_HISTORY", getAgentHistorySaga),
    yield takeLatest("GET_ADDED_AGENTS", getAddedAgentsSaga),
    yield takeLatest(CREATE_STRIPE_SESSION, createStripeSessionSaga),
    yield takeLatest("CUSTOMISE_BOARD_ENTITY", customiseBoardEntitySaga),
    yield takeLatest("DELETE_BOARD_ENTITY", deleteBoardEntitySaga),
    yield takeLatest("CREATE_NEW_ISSUE_PRIORITY", createNewIssuePrioritySaga),
    yield takeLatest("CREATE_NEW_ISSUE_STATUS", createNewIssueStatusSaga),
    yield takeLatest("REORDER_BOARD_ENTITY", reorderBoardEntitySaga),
    yield takeLatest("REMOVE_BOARD_MEMBER", removeBoardMemberSaga),
    yield takeLatest("GET_BOARD_MEMBERS_ROLES", getBoardMembersRolesSaga),
    yield takeLatest("UPDATE_BOARD_MEMBERS_ROLES", updateBoardMembersRolesSaga),
    yield takeLatest("GET_USER_META", getUserMetaSaga),
    yield takeLatest("UPDATE_USER_META", updateUserMetaSaga),
    yield takeLatest("UPDATE_USER_INFO", updateUserInfoSaga),
    yield takeLatest("GET_TEXT_FROM_DOC", gettextFromDocSaga),
    yield takeLatest("GET_TEXT_TO_SPACE", getTextToSpaceSaga),
    yield takeLatest(
      "UPDATE_WORKSPACE_DETAILS",
      handleWorkspaceDetailsUpdateSaga
    ),
    yield takeLatest("GET_ALL_WORKSPACES", getAllWorkspacesSaga),
    yield takeLatest(DELETE_WORKSPACE, deleteWorkspaceSaga),
    yield takeLatest("UPDATE_EDIT_ACCESS", updateEditAccessSaga),
    yield takeLatest("GET_LAUNCH_CONFIG", getLaunchConfigSaga),
    yield takeLatest("UPDATE_ORGANISATION", updateOrganisationSaga),
    yield takeLatest("CREATE_NEW_WORKSPACE", createNewWorkspaceSaga),
    yield takeLatest("VALIDATE_WORKSPACE_NAME", validateWorkspaceNameSaga),
    yield takeLatest("VALIDATE_ACTION_KEY", validateActionKeySaga),
    yield takeLatest("GET_ALL_ASSISTANTS", getAllAssistantsSaga),
    yield takeLatest("GET_HISTORY_SESSIONS", getHistorySessionsSaga),
    yield takeLatest("GET_SESSION_HISTORY_CHAT", getSessionHistoryChatSaga),
    yield takeLatest("SEND_NEW_PROMPT", sendNewPromptSaga),
    yield takeLatest("GENERATE_TICKET_DETAILS", generateTicketdetailsSaga),
    yield takeLatest("REGENERATE_PROMPT", regeneratePromptSaga),
    yield takeLatest("CLEAR_HISTORY", clearHistorySaga),
    yield takeLatest("CLEAR_ALL_HISTORY", clearAllHistorySaga),
    yield takeLatest("VALIDATE_ASSISTANT_HANDLE", validateAssistantHandleSaga),
    yield takeLatest("IMPROVE_WRITING", improveWritingSaga),
    yield takeLatest("CREATE_ASSISTANT", createAssistantSaga),
    yield takeLatest("DELETE_ASSISTANT", deleteAssistantSaga),
    yield takeLatest("UPDATE_ASSISTANT", updateAssistantSaga),
    yield takeLatest("UPDATE_ASSISTANT_TASK", updateAssistantTaskSaga),
    yield takeLatest("DELETE_ASSISTANT_TASK", deleteAssistantTaskSaga),
    yield takeLatest("GET_ALL_ASSISTANT_TASKS", getAllAssistantTasksSaga),
    yield takeLatest("CREATE_ASSISTANT_TASK", createAssistantTaskSaga),
    yield takeLatest("UPLOAD_ASSISTANT_AUDIO", uploadAssistantAudioSaga),
    yield takeLatest("INTERACTION", interactionSaga),
    yield takeLatest("GET_HOME_FEED_FILTERS", getHomeFeedFiltersSaga),
    yield takeLatest("UPDATE_HOME_FEED_FILTERS", updateHomeFeedFiltersSaga),
    yield takeLatest("REQUEST_DOCUMENT_ACCESS", requestDocumentAccessSaga),
    yield takeLatest("ACCESS_PROVIDED", accessProvidedSaga),
    yield takeLatest("GET_INFO_BANNER", infoBannerSaga),
    yield takeLatest("GET_ACTION_ACTIVITIES", getActionActivitiesSaga),
    yield takeLatest("INSERT_NOTIFICATION", insertNotificationSaga),
    yield takeLatest("MARK_ONBOARDING_STEP", markOnboardingStepSaga),
    yield takeLatest("GET_NOTIFICATION_SETTINGS", getNotificationSettingsSaga),
    yield takeLatest("UPDATE_NOTIFICATION_SETTINGS", updateNotificationSettingsSaga),
    yield takeLatest(
      "GET_PUBLIC_WORKSPACE_DETAILS",
      getPublicWorkspaceDetailsSaga
    ),
    yield takeLatest("GET_ALL_WORKSPACE_INVITES", getAllWorkspaceInvitesSaga),
    yield takeLatest("RESEND_WORKSPACE_INVITE", resendWorkspaceInviteSaga),
    yield takeLatest("REMOVE_WORKSPACE_INVITE", removeWorkspaceInviteSaga),
    yield takeLatest(
      GET_STRIPE_TRANSACTION_STATUS,
      getStripeTransactionStatusSaga
    ),
    yield takeLatest(GET_STRIPE_PLANS, getStripePlansSaga),
    yield takeLatest(GET_CURRENT_PLAN, getCurrentPlanSaga),
    yield takeLatest(GET_REPORTS, getReportsSaga),
    yield takeLatest("GET_APP_INTEGRATIONS", getAppIntegrationsSaga),
    yield takeLatest("UPDATE_APP_INTEGRATIONS", updateAppIntegrationsSaga),
  ];

  yield all(sagaIndex);
}
