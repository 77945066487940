import {
  CREATE_NEW_TASK_SUCCESS,
  CREATE_NEW_TASK_FAILURE,
  SET_TICKET_DETAILS,
  SET_TICKET_MULTIMEDIA,
  SET_TICKET_ACTIVITY,
  GET_ALL_COMMENTS,
  SET_ALL_COMMENTS,
  CHANGES_SAVED,
  GET_SITE_MEMBERS,
  SET_SITE_MEMBERS,
  SET_SITE_OWNER,
  SET_TICKET_WORK_LOG,
  SET_TICKET_REPORTER,
  GET_TICKET_REPORTER,
  SET_TASKS,
  RESET_TASKS,
  CREATE_NEW_TASK
} from "../actions/types";

const initialState = {
  creatingNewTask: false,
  ticketDetailsLoading: false,
  ticketDetails: null,
  ticketAttachmentsLoading: false,
  ticketAttachments: null,
  ticketActivityLoading: false,
  ticketActivity: null,
  ticketCommentsLoading: false,
  ticketComments: null,
  updatingDetails: false,
  loadingSiteMembers: false,
  siteMembers: [],
  ticketWorkLogLoading: false,
  ticketWorkLog: null,
  ticketReporter: null,
  ticketReporterLoading: false,
  tasks: null,
  actionActivities: null,
  actionActivitiesLoading: false,
  actionActivitiesUnreadCount: 0,
};

export default function taskReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGES_SAVED:
      return {
        ...state,
        updatingDetails: false,
      };

    case CREATE_NEW_TASK:
      return {
        ...state,
        creatingNewTask: true,
      };

    case CREATE_NEW_TASK_SUCCESS:
      return {
        ...state,
        creatingNewTask: false,
      };

    case CREATE_NEW_TASK_FAILURE:
      return {
        ...state,
        creatingNewTask: false,
      };

    case SET_TICKET_DETAILS:
      return {
        ...state,
        ticketDetailsLoading: false,
        ticketDetails: action.data,
      };

    case SET_TICKET_MULTIMEDIA:
      return {
        ...state,
        ticketAttachmentsLoading: false,
        ticketAttachments: action.data,
      };

    case SET_TICKET_ACTIVITY:
      return {
        ...state,
        ticketActivityLoading: false,
        ticketActivity: action.data,
      };

    case SET_TICKET_WORK_LOG:
      return {
        ...state,
        ticketWorkLogLoading: false,
        ticketWorkLog: action.data,
      };

    case GET_ALL_COMMENTS:
      return {
        ...state,
        ticketCommentsLoading: true,
      };

    case SET_ALL_COMMENTS:
      return {
        ...state,
        ticketCommentsLoading: false,
        ticketComments: action.data,
      };

    case GET_SITE_MEMBERS:
      return {
        ...state,
        loadingSiteMembers: true,
      };

    case SET_SITE_MEMBERS:
      let temp = [...state.siteMembers];
      temp.push(...action.data);

      return {
        ...state,
        loadingSiteMembers: false,
        siteMembers: action.data,
      };

    case SET_SITE_OWNER:
      return {
        ...state,
      };
    case GET_TICKET_REPORTER:
      return {
        ...state,
        ticketReporterLoading: true,
      };

    case SET_TASKS:
      return {
        ...state,
        tasks: action.data,
      };

    case RESET_TASKS:
      return {
        ...state,
        tasks: null,
      };

    case SET_TICKET_REPORTER:
      return {
        ...state,
        ticketReporterLoading: false,
        ticketReporter: action.data,
      };

    case "SET_ALL_ACTION_ACTIVITIES":
      return {
        ...state,
        actionActivities: action.data,
      };

    case "SET_ALL_ACTION_ACTIVITIES_UNREAD_COUNT":
      return {
        ...state,
        actionActivitiesUnreadCount: action.data,
      };

    case "ACTION_ACTIVITIES_LOADING":
      return {
        ...state,
        actionActivitiesLoading: action.data,
      };

    default:
      return state;
  }
}
