import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const env = require(`../../.config.${process.env.REACT_APP_ENV}.json`);
export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const domain = env.auth0Domain;
  const clientId = env.auth0ClientId;
  const redirectUri = `${window.location.origin}/log-in`;
  // const redirectUri = `${window.location.origin}`;

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  // Only include screen_hint for signup if we're on the signup path
  const authorizationParams = {
    redirect_uri: redirectUri,
    ...(location.pathname === '/sign-up' && { screen_hint: 'signup' })
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}
      useRefreshTokens={true}
      cacheLocation={"localstorage"}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
