export const setAppIntegrationsAction = (integrations) => ({
  type: 'SET_APP_INTEGRATIONS',
  params: integrations
});

export const getAppIntegrationsAction = (data) => ({
  type: "GET_APP_INTEGRATIONS",
  params: data
});

export const updateAppIntegrationsAction = (integration) => ({
  type: 'UPDATE_APP_INTEGRATIONS',
  params: integration
});

