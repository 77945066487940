const env = require(`./.config.${process.env.REACT_APP_ENV}.json`);
export const getDateString = (dateString) => {
  let year;
  if (year < new Date().getFullYear())
    year = " " + new Date(dateString).getFullYear();
  else year = "";
  const dateStringObj = new Date(dateString);
  let formatString =
    String(dateStringObj.toLocaleString("en-US", { month: "short" })) +
    " " +
    dateStringObj.getDate() +
    year +
    " • " +
    dateStringObj.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  return formatString;
};

export const getFullscreenPadding = (screenWidth) => {
  // return screenWidth > 1024 ? "5.25em 1.5em 0em 17em" : "6.25em 2em 9em 2em";
};



export const getDataUrl = async (url) => {
    console.log(`Attempting to fetch image: ${url}`);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          console.log(`Successfully converted image to data URL: ${url}`);
          resolve(reader.result);
        };
        reader.onerror = () => {
          console.error(`Failed to convert image to data URL: ${url}`);
          reject(new Error('FileReader error'));
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error(`Failed to fetch image: ${url}`, error);
      throw error;
    }
  };

export const convertBlocksToHtml = async (blocks) => {
  let html = '';
  for (const block of blocks) {
    switch (block.type) {
      case 'paragraph':
        html += `<p>${block.content.map(c => c.text).join('')}</p>`;
        break;
      case 'heading':
        html += `<h${block.props.level}>${block.content.map(c => c.text).join('')}</h${block.props.level}>`;
        break;
      case 'checkListItem':
        const checked = block.props.checked ? 'checked' : '';
        html += `<div><input type="checkbox" ${checked} disabled> ${block.content.map(c => c.text).join('')}</div>`;
        break;
      case 'table':
        html += '<table border="1">';
        if (block.content && block.content.type === 'tableContent' && Array.isArray(block.content.rows)) {
          html += block.content.rows.reduce((rowsHtml, row) => {
            if (Array.isArray(row.cells)) {
              rowsHtml += '<tr>';
              rowsHtml += row.cells.reduce((cellsHtml, cell) => {
                cellsHtml += '<td>';
                if (Array.isArray(cell)) {
                  cellsHtml += cell.reduce((itemsHtml, item) => {
                    if (item.type === 'text') {
                      itemsHtml += item.text;
                    }
                    return itemsHtml;
                  }, '');
                }
                cellsHtml += '</td>';
                return cellsHtml;
              }, '');
              rowsHtml += '</tr>';
            }
            return rowsHtml;
          }, '');
        }
        html += '</table>';
        break;
      case 'image':
        html += `<img src="${block.props.url}" alt="${block.props.caption || ''}" style="max-width: 100%;">`;
        if (block.props.caption) {
          html += `<figcaption>${block.props.caption}</figcaption>`;
        }
        break;
      case 'bulletListItem':
        html += `<ul><li>${block.content.map(c => c.text).join('')}</li></ul>`;
        break;
      case 'numberedListItem':
        html += `<ol><li>${convertContentToHtml(block.content)}</li></ol>`;
        break;
      default:
        console.warn(`Unsupported block type: ${block.type}`);
        html += `<p>${JSON.stringify(block)}</p>`;
    }
  }
  return html;
};

const convertContentToHtml = (content) => {
  return content.map(item => {
    switch (item.type) {
      case 'text':
        return applyStyles(item.text, item.styles);
      case 'link':
        return `<a href="${item.href}">${convertContentToHtml(item.content)}</a>`;
      default:
        return item.text || '';
    }
  }).join('');
};

const applyStyles = (text, styles) => {
  if (!styles) return text;
  let styledText = text;
  if (styles.bold) styledText = `<strong>${styledText}</strong>`;
  if (styles.italic) styledText = `<em>${styledText}</em>`;
  if (styles.underline) styledText = `<u>${styledText}</u>`;
  return styledText;
};

export const convertToPlainText = (editor) => {
  if (!editor || !editor.document || !Array.isArray(editor.document)) {
    console.error('Invalid editor or document structure', editor);
    return '';
  }

  const plainText = editor.document.reduce((acc, block) => {
    console.log('Processing block:', JSON.stringify(block, null, 2));  // Log each block for debugging

    switch (block.type) {
      case 'paragraph':
        return acc + block.content.map(item => item.text).join('') + '\n\n';
      case 'heading':
        const headingLevel = block.props.level;
        const headingText = block.content.map(item => item.text).join('');
        return acc + '#'.repeat(headingLevel) + ' ' + headingText + '\n\n';
      case 'bulletListItem':
        return acc + '- ' + block.content.map(item => item.text).join('') + '\n';
      case 'numberedListItem':
        return acc + '1. ' + block.content.map(item => item.text).join('') + '\n';
      case 'taskListItem':
        const checkbox = block.props.checked ? '[x]' : '[ ]';
        return acc + checkbox + ' ' + block.content.map(item => item.text).join('') + '\n';
      case 'quote':
        return acc + '> ' + block.content.map(item => item.text).join('') + '\n\n';
      case 'code':
        return acc + '```\n' + block.props.code + '\n```\n\n';
      case 'image':
        return acc + `![${block.props.alt || ''}](${block.props.url})\n\n`;
      case 'horizontalRule':
        return acc + '---\n\n';
      case 'table':
        if (block.content && block.content.type === 'tableContent' && Array.isArray(block.content.rows)) {
          return acc + block.content.rows.reduce((tableAcc, row) => {
            if (Array.isArray(row.cells)) {
              return tableAcc + '| ' + row.cells.map(cell => 
                Array.isArray(cell) ? cell.map(item => item.text || '').join('') : ''
              ).join(' | ') + ' |\n';
            }
            return tableAcc;
          }, '') + '\n';
        }
        return acc;
      default:
        console.warn('Unknown block type:', block.type);
        return acc + (block.content ? block.content.map(item => item.text).join('') : '') + '\n\n';
    }
  }, '');

  return plainText.trim();
}


export const getPresignedUrlSpace = async (fileName) => {
  const response = await fetch(
    `${env.newBaseURLDev}/media/get_presigned_url_space/?filename=${fileName}&workspace_id=${
      JSON.parse(localStorage.getItem("new_workspace"))?.id
    }`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await response.json();
};

export const uploadOnAWS = async (file) => {
  const result = await getPresignedUrlSpace(file.name);
  const formData = new FormData();
  formData.append("key", result.post.fields.key);
  formData.append("AWSAccessKeyId", result.post.fields.AWSAccessKeyId);
  formData.append("policy", result.post.fields.policy);
  formData.append("signature", result.post.fields.signature);
  formData.append("file", file, file.name);

  const response = await fetch(result.post.url, {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return {
    success: true,
    url: `${result.post.url}${result.post.fields.key}`,
  };
}; 

export const formatDate = (dateString,onlyDate = false) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric'};
  const date = new Date(dateString);
  if(onlyDate){
    return date.toLocaleDateString('en-US', options);
  }else{
    return date.toLocaleDateString('en-US', options) + " at " + date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'});
  }
};

export const checkUserEditAccess = (item) => {
  const userID = JSON.parse(localStorage.getItem("new_resource_owner"))?.uid;
  if (item?.access_map) {
    const sortedAccessMap = Object.entries(item.access_map).sort(([keyA], [keyB]) => {
      if (item.access_map[keyA].role === 'owner') return -1;
      if (item.access_map[keyB].role === 'owner') return 1;
      return 0;
    });
    const sortedAccessObject = Object.fromEntries(sortedAccessMap);
    item.access_map = sortedAccessObject;
  }
  if(Object.keys(item?.access_map).length > 0 && item?.access_map[userID] && item?.access_map[userID]?.role !== "viewer"){
    return true;
  }else{
    return false;
  }
  
};

export const getUserAccessFolders = (spacesData) => {
  const userID = JSON.parse(localStorage.getItem("new_resource_owner"))?.uid;
  if (!spacesData) return [];
  const userAccessFolders = spacesData?.filter((folder) => {
    if(folder?.access_map){
      return folder?.access_map[userID] &&folder?.access_map[userID]?.role !== "viewer";
    }
    return false;
  });
  return userAccessFolders;
}

export const getOrganisationDetails = () => {
  const orgDetails = JSON.parse(localStorage.getItem("new_org_details"));
  const workspace = JSON.parse(localStorage.getItem("new_workspace"));
  const board = JSON.parse(localStorage.getItem("new_board"));
  return { orgDetails, workspace, board };
}


export const timeAgo = (date) => {
  if (isNaN(date.getTime())) {
    return "";
  }
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return "just now";
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minutes ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 30) {
    return `${diffInDays} days ago`;
  }

  const diffInMonths = Math.floor(diffInDays / 30);
  return `${diffInMonths} months ago`;
}
