import { combineReducers } from "redux";
import authReducer from "./authReducer";
import toastReducer from "./toastReducer";
import drawerReducer from "./drawerReducer";
import modalReducer from "./modalReducer";
import siteReducer from "./siteReducer";
import boardReducer from "./boardReducer";
import laneReducer from "./laneReducer";
import issueReducer from "./issueReducer";
import taskReducer from "./taskReducer";
import dashboardReducer from "./dashboardReducer";
import KPIReducer from "./KPIReducer";
import notificationsReducer from "./notificationsReducer";
import loaderReducer from "./loaderReducer";
import manHoursReducer from "./manHoursReducer";
import searchReducer from "./searchReducer";
import settingReducer from "./settingReducer";
import appReducer from "./appReducer";
import homeReducer from "./homeReducer";
import spacesReducer from "./spacesReducer";
import stripeReducer from "./stripeReducer";
import marketplaceReducer from "./marketplaceReducer";
import reportsReducer from "./reportsReducer";
import userMetaReducer from "./userMetaReducer";
import workspaceReducer from "./workspaceReducer";
import assistantReducer from "./assistantReducer";
import appIntegrationsReducer from "./appIntegrationsReducer";
// import newToastReducer from "./newToastReducer";
const allReducers = combineReducers({
  auth: authReducer,
  toast: toastReducer,
  drawer: drawerReducer,
  modal: modalReducer,
  site: siteReducer,
  board: boardReducer,
  lane: laneReducer,
  issue: issueReducer,
  task: taskReducer,
  dashboard: dashboardReducer,
  kpi: KPIReducer,
  notifications: notificationsReducer,
  loader: loaderReducer,
  manHours: manHoursReducer,
  search: searchReducer,
  setting: settingReducer,
  appState: appReducer,
  home: homeReducer,
  stripe: stripeReducer,
  spaces: spacesReducer,
  marketplace: marketplaceReducer,
  // newToast: newToastReducer,
  reports: reportsReducer,
  userMeta: userMetaReducer,
  workspace: workspaceReducer,
  assistant: assistantReducer,
  appIntegrations: appIntegrationsReducer
});

export default allReducers;
