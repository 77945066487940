import React, { Suspense, useEffect, useState, useRef, useMemo } from "react";
import SEO from "./components/SEO/SEO";
import "./Alert.css";
import {
  Navigate,
  Route,
  Routes,
  //  useNavigate
} from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { connect, Provider } from "react-redux";
import { store } from "./store";
import loadable from "@loadable/component";
import { getAllNotificationsAction, getNotificationSettingsAction } from "./actions/notificationsActions";
import { getSpacesDataAction,getAllFolderDocumentsAction } from "./actions/spacesActions";
import { getInfoBannerAction } from "./actions/appActions";
import { ToastAlert,Confetti,Alert } from "@presto-labs-ai/design-system-new";
import ThemeProvider from "./contexts/theme/ThemeContext";
import Search from "./components/Search/Search";
import { toggleSearch } from "./actions/searchActions";
import { getHomeFeedAction } from "./actions/homeActions";
import { getMarketplaceDataAction,getAgentHistoryAction,getAddedAgentsAction } from "./actions/marketplaceActions";
import { checkStripeTransactionAction, getLaunchConfigAction } from "./actions/stripeActions";
import {getAllWorkspacesAction} from "./actions/workspaceActions";
import { getTasksAction } from "./actions/taskActions";
import CustomModal from "./components/Modals/CustomModal";
import SplashScreen from "./views/Authentication/SplashScreen";
import { getAppIntegrationsAction } from "./actions/appIntegrationsActions";
import { getAllAssistantsAction,getAllAssistantTasksAction } from "./actions/assistantActions";
import {
  getAllBoardEntityAction,
  getBoardMembersAction,
  getBoardMembersRolesAction,
} from "./actions/boardActions";
import { getUserMetaAction } from "./actions/userMetaActions";

import useToastAlert from "./hooks/useToast";

const CommonModal = loadable(() => import("./components/Modals/CommonModal"));
const SideDrawer = loadable(() => import("./components/SideDrawer/SideDrawer"));
const Authentication = loadable(() =>
  import("./views/Authentication/Authentication")
);
const PageNotFound = loadable(() =>
  import("./views/ErrorScreens/PageNotFound")
);

const Home = loadable(() => import("./views/Home/Home"));
const Tasks = loadable(() => import("./views/Tasks/Tasks"));
const Helpers = loadable(() => import("./views/Helpers/Helpers"));

const Reports = loadable(() => import("./views/Dashboard/Reports"));

const WorkspaceSettingsNew = loadable(() =>
  import("./views/WorkspaceSettingsNew/WorkspaceSettingsNew")
);
const PersonalSettingsNew = loadable(() =>
  import("./views/PersonalSettingsNew/PersonalSettingsNew")
);

const Assistant = loadable(() => import("./views/Assistant/Assistant"));
const NotificationPage = loadable(() =>
  import("./views/Notifications/Notifications")
);

const Billing = loadable(() => import("./views/Billing/Billing"));
const ExplorePlan = loadable(() => import("./views/Billing/ExplorePlan"));
const Assets = loadable(() => import("./views/Assets/Assets"));
const Agents = loadable(() => import("./views/Agents/Agents"));
const Spaces = loadable(() => import("./views/Spaces/Spaces"));
const Marketplace = loadable(() => import("./views/Marketplace/Marketplace"));
const Onboarding = loadable(() => import("./views/Onboarding/Onboarding"));
const OrganisationSettings = loadable(() => import("./views/Organisation/OrganisationSettings"));
const SwitchOrganisation = loadable(() => import("./views/Organisation/SwitchOrganisation"));

const TeamManagement = loadable(() =>
  import("./views/TeamManagement/TeamManagement")
);
const PaymentSuccess = loadable(() => import("./views/PaymentSuccess/Success"));

const searchParams = new URLSearchParams(document.location.search);

const App = ({
  message,
  toastType,
  toastId,
  toastAutoHide,
  showSearch,
  toggleSearch,
  getAllNotificationsAction,
  getAllBoardEntityAction,
  getBoardMembersAction,
  getSpacesDataAction,
  checkStripeTransaction,
  getMarketplaceDataAction,
  getAgentHistoryAction,
  getAddedAgentsAction,
  getBoardMembersRolesAction,
  showCustomModal,
  getUserMetaAction,
  getTasksAction,
  getAllFolderDocumentsAction,
  getLaunchConfigAction,
  getAllWorkspacesAction,
  launchConfig,
  showConfetti,
  getAllAssistantsAction,
  getAllAssistantTasksAction,
  infoBanner,
  getInfoBannerAction,
  getNotificationSettingsAction,
  getAppIntegrationsAction
}) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("Error Interceptor:", error);

      if (error.response?.status === 401 || error.status === 404) {
        localStorage.removeItem("token");
      }

      return Promise.reject(error);
    }
  );

  const { pathname } = window.location;
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(
    () => localStorage.getItem("isSideNavExpanded") === "true"
  );

  const [fullScreenPadding, setFullScreenPadding] = useState(null);
  const [isAppLoaded,setIsAppLoaded] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);  
  const [bannerList,setBannerList] = useState([]);
  const bannerListRef = useRef(null);
  const [bannerHeight, setBannerHeight] = useState(0);
  const hideSideNavPaths = useMemo(() => [
    "/switch-organisation",
    "/log-in",
    "/sign-up",
    "/onboarding",
    "/join",
  ], []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        localStorage.getItem("token") &&
        (event.metaKey || event.ctrlKey) &&
        event.key === "k"
      ) {
        event.preventDefault();
        toggleSearch(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [toggleSearch]);
  useEffect(() => {

    if (hideSideNavPaths.includes(pathname)) {
      setFullScreenPadding("0em");
      return;
    }

    let padding =
      windowSize[0] > 768
        ? isSideNavExpanded && !hideSideNavPaths.includes(pathname)
          ? "0px 0px 0px 312px"
          : "0px 0px 0px 80px"
        : "0px 0px 0px 80px";
    setFullScreenPadding(padding);

  }, [isSideNavExpanded, pathname, windowSize, hideSideNavPaths]);

  const {
    notifications: toastNotifications,
    showNotification,
    hideNotification,
  } = useToastAlert(message, toastType, toastId, toastAutoHide);

  // Add this useEffect to make showNotification available globally
  useEffect(() => {
    window.showNotification = showNotification;
    return () => {
      delete window.showNotification;
    };
  }, [showNotification]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {  
      getAllWorkspacesAction({onSuccess: (data) => {
        const selectedOrgDetails = JSON.parse(localStorage.getItem("new_org_details"));
        localStorage.setItem("new_org_details", JSON.stringify(data?.organisations?.find(org => org?.id === selectedOrgDetails?.id) || data?.organisations[0]));
        getLaunchConfigAction({onSuccess:(data) =>{
          setIsAppLoaded(true);
        }});
      }});
      const lastPayment = JSON.parse(localStorage.getItem("lastPayment"));
      if (lastPayment) {
        checkStripeTransaction({
          session_id: lastPayment.session_id,
          transaction_id: lastPayment.transaction_id,
        });
        localStorage.removeItem("lastPayment");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(launchConfig) {
      getInfoBannerAction({workspace_id: JSON.parse(localStorage.getItem("new_workspace")).id});
      if(launchConfig.access?.notifications?.allowed){
        getAllNotificationsAction({page: 1});
      }
      if(launchConfig.access?.spaces?.allowed){
        getSpacesDataAction();
        getAllFolderDocumentsAction();
      }

      if(launchConfig.access?.marketplace?.allowed){
        getMarketplaceDataAction();
      }
      getNotificationSettingsAction({workspace_id: JSON.parse(localStorage.getItem("new_workspace")).id});
      getAllAssistantsAction({workspace_id: JSON.parse(localStorage.getItem("new_workspace")).id,org_id: JSON.parse(localStorage.getItem("new_org_details")).id});
      getAllAssistantTasksAction({workspace_id: JSON.parse(localStorage.getItem("new_workspace")).id});
      getAgentHistoryAction({page: 1});
      getAddedAgentsAction();
      getAllBoardEntityAction(JSON.parse(localStorage.getItem("new_board")).id);
      getBoardMembersAction(JSON.parse(localStorage.getItem("new_board")).id);
      getBoardMembersRolesAction();
      getTasksAction({ board_id: JSON.parse(localStorage.getItem("new_board")).id });
      getUserMetaAction();
      getAppIntegrationsAction({workspace_id: JSON.parse(localStorage.getItem("new_workspace")).id});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchConfig]);

  useEffect(() => {
    if (infoBanner?.banner_details && infoBanner?.banner_details.length > 0) {
      const closedBanners = JSON.parse(localStorage.getItem('closedBanners') || '[]');
      
      const activeBannerIds = infoBanner.banner_details.map(banner => banner.id);
      const updatedClosedBanners = closedBanners.filter(id => activeBannerIds.includes(id));

      localStorage.setItem('closedBanners', JSON.stringify(updatedClosedBanners));
      
      const activeBanners = infoBanner.banner_details.filter(
        banner => !updatedClosedBanners.includes(banner.id) && banner.is_active && !banner.is_disabled
      );

      setBannerList(activeBanners);
    }
  }, [infoBanner]);

  useEffect(() => {
    if (toastType !== null) {
      notify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastType]);

  const notify = () =>
    toastType === 0
      ? showNotification({
          id: toastId ?? Date.now(),
          message,
          variant: "error",
          autoHide: toastAutoHide,
        })
      : toastType === 1
      ? showNotification({
          id: toastId ?? Date.now(),
          message,
          variant: "success",
          autoHide: toastAutoHide,
        })
      : showNotification({
          id: toastId ?? Date.now(),
          message,
          variant: "primary",
          autoHide: toastAutoHide,
        });

  const handleSideNavExpansion = (isExpanded) => {
    setIsSideNavExpanded(isExpanded);
  };

  const handleAlertClose = (bannerId) => {
    if (bannerId) {
      const closedBanners = JSON.parse(localStorage.getItem('closedBanners') || '[]');
      if (!closedBanners.includes(bannerId)) {
        closedBanners.push(bannerId);
        localStorage.setItem('closedBanners', JSON.stringify(closedBanners));

        setBannerList(prevList => prevList.filter(banner => banner.id !== bannerId));
      }
    }
  }

  // Add useEffect to update bannerHeight when bannerListRef changes
  useEffect(() => {
    const updateBannerHeight = () => {
      setBannerHeight(bannerListRef?.current?.offsetHeight || 0);
    };

    updateBannerHeight();
    const resizeObserver = new ResizeObserver(updateBannerHeight);
    const currentBannerList = bannerListRef.current; // Capture the current value

    if (currentBannerList) {
      resizeObserver.observe(currentBannerList);
    }

    return () => {
      if (currentBannerList) { // Use captured value in cleanup
        resizeObserver.unobserve(currentBannerList);
      }
    };
  }, [bannerList]);

  return (
    <>
      <SEO title="Presto Labs" description="Elevate your productivity and empower your team with tailor-made assistants fueled by your company's expertise." />
      {(!localStorage.getItem("token") || ((isAppLoaded || window.location.pathname === "/onboarding" || window.location.pathname === "/join") && localStorage.getItem("token"))) ? <Provider store={store}>
        <Suspense fallback={<SplashScreen/>}>

          { showConfetti && <Confetti size="md"/>}
          <ThemeProvider>

            {/* Note - This the Modal renderer, All the modals are mounted here. Currently we have handlers which are use to show modals.*/}
            <CommonModal />
            {showCustomModal && <CustomModal />}
            {/* To Do: Move in a separate file (App Alerts)  */}
            {toastNotifications.length > 0 && (
              <div className="fixed bottom-8 right-8 flex flex-col gap-2 z-[9999]">
                {toastNotifications.map((notification) => (
                  <div className="alert_animation" key={notification.id}>
                    <ToastAlert
                      id={notification.id}
                      message={notification.message}
                      variant={notification.variant}
                      onClose={hideNotification}
                    />
                  </div>
                ))}
              </div>
            )}

            {showSearch && <Search />}

            <div className="flex flex-col" ref={bannerListRef}>
              {bannerList.length > 0 && bannerList.map((banner) => {
                return <div>
                <Alert
                  message={<span className="text-sm font-medium"><span className="font-bold">{banner?.card_title}</span> {" - "} {banner?.description}</span>}
                  alertType={banner?.card_type}
                  showCloseButton={true}
                  onClose={() => {handleAlertClose(banner?.id)}}
                />
              </div>
              })}
            </div>

            {localStorage.getItem("token") && 
             !hideSideNavPaths.includes(window.location.pathname) ? (
              <SideDrawer 
                onSideNavExpansion={handleSideNavExpansion} 
                launchConfig={launchConfig} 
                bannerHeight={bannerHeight}
              />
            ) : (
              <></>
            )}

            <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{ 
              padding: fullScreenPadding,
              height: `calc(100vh - ${bannerHeight}px)`,
              overflow: "auto"
            }}
            className="transition-all duration-500 ease-in-out "
            >
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<Navigate to="/assistant" replace />}
                />
                <Route path="/page-not-found" Component={PageNotFound} />
                <Route
                  exact
                  path="/log-in"
                  element={<PublicRoute component={Authentication} />}
                />
                <Route
                  exact
                  path="/sign-up"
                  element={<PublicRoute component={Authentication} />}
                />
                <Route
                  exact
                  path="/home"
                  element={<PrivateRoute component={Home} />}
                />
                <Route
                  exact
                  path="/dashboards"
                  element={<PrivateRoute component={Reports} />}
                />
                <Route
                  exact
                  path="/tasks"
                  element={<PrivateRoute component={Tasks} />}
                />
                <Route
                  exact
                  path="/workspace-settings"
                  element={<PrivateRoute component={WorkspaceSettingsNew} />}
                />
                <Route
                  exact
                  path="/personal-settings"
                  element={<PrivateRoute component={PersonalSettingsNew} />}
                />
                <Route
                  exact
                  path="/assistant"
                  element={<PrivateRoute component={Assistant} />}
                />
                <Route
                  exact
                  path="/switch-organisation"
                  element={<PrivateRoute component={SwitchOrganisation} />}
                />
                <Route
                  exact
                  path="/notifications"
                  element={<PrivateRoute component={NotificationPage} />}
                />
                <Route
                  exact
                  path="/billing"
                  element={<PrivateRoute component={Billing} />}
                />
                <Route
                  exact
                  path="/billing/explore-plan"
                  element={<PrivateRoute component={ExplorePlan} />}
                />
                <Route
                  exact
                  path="/assets"
                  element={<PrivateRoute component={Assets} />}
                />
                <Route
                  exact
                  path="/agents"
                  element={<PrivateRoute component={Agents} />}
                />
                <Route
                  exact
                  path="/onboarding"
                  element={<PublicRoute component={Onboarding} />}
                />
                <Route
                  exact
                  path="/join"
                  element={<PublicRoute component={Authentication} />}
                />
                <Route
                  exact
                  path="/manage-team"
                  element={<PrivateRoute component={TeamManagement} />}
                />
                <Route
                  exact
                  path="/spaces"
                  element={<PublicRoute component={Spaces} />}
                />
                <Route
                  exact
                  path="/marketplace"
                  element={<PrivateRoute component={Marketplace} />}
                />
                <Route
                  exact
                  path="/payment/success"
                  element={<PaymentSuccess />}
                />
                <Route
                  exact
                  path="/organisation-settings"
                  element={<PrivateRoute component={OrganisationSettings} />}
                />
                <Route
                  exact
                  path="/helpers"
                  element={<PrivateRoute component={Helpers} />}
                />
                <Route
                  path="*"
                  element={<Navigate to="/page-not-found" replace />}
                />
                <Route exact path="/success" element={<PaymentSuccess />} />
              </Routes>
            </motion.div>
          </ThemeProvider>
        </Suspense>
      </Provider>
       : 
       <SplashScreen/>}
      </>
  );
};

const PrivateRoute = ({ component: Component }) => {
  const id = document.location.search;

  return localStorage.getItem("token") ? (
    <Component key={id} />
  ) : (
    <Navigate
      to={`/log-in?redirect=${
        window.location.pathname + window.location.search
      }`}
    />
  );
};

const PublicRoute = ({ component: Component }) => {
  const id = document.location.search;
  if (document.location.pathname === "/log-in" && searchParams.get("workspace_invite_slug")) {
    localStorage.setItem("workspaceInviteSlug", searchParams.get("workspace_invite_slug"));
  }

  return localStorage.getItem("token") ? (
    document.location.pathname === "/spaces" ? (
      <Component key={id} />
    ) : (
      document.location.pathname === "/onboarding" || document.location.pathname === "/join" || document.location.pathname === "/tasks" ? (
        <Component key={id} />
      ) : (
        <Navigate to={{ pathname: "/assistant" }} />
      )
    )
  ) : (
    <Component key={id} />
  );
};

const mapStateToProps = (state) => ({
  message: state.toast.message,
  displayLoader: state.loader.display,
  toastType: state.toast.toastType,
  toastId: state.toast.toastId,
  toastAutoHide: state.toast.autoHide,
  drawerOpen: state.drawer.drawerOpen,
  showSearch: state.search.showSearch,
  showCustomModal: state.modal.showCustomModal,
  launchConfig: state.stripe.launchConfig,
  showConfetti: state.appState.showConfetti,
  infoBanner: state.appState.infoBanner
});

const mapDispatchToProps = {
  toggleSearch: toggleSearch,
  getHomeFeedAction: getHomeFeedAction,
  getAllNotificationsAction: getAllNotificationsAction,
  getAllBoardEntityAction: getAllBoardEntityAction,
  getBoardMembersAction: getBoardMembersAction,
  getSpacesDataAction: getSpacesDataAction,
  checkStripeTransaction: checkStripeTransactionAction,
  getMarketplaceDataAction,
  getAgentHistoryAction,
  getAddedAgentsAction,
  getBoardMembersRolesAction,
  getUserMetaAction,
  getTasksAction,
  getAllFolderDocumentsAction,
  getLaunchConfigAction,
  getAllWorkspacesAction,
  getAllAssistantsAction,
  getAllAssistantTasksAction,
  getInfoBannerAction,
  getNotificationSettingsAction,
  getAppIntegrationsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
